import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import styled from 'styled-components';

function SmallModal({ children, title }) {
  return (
    <ModalS bsSize="lg" aria-labelledby="contained-modal-title-sm">
      <ModalHeader closeButton>
        <Title>{title}</Title>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </ModalS>
  );
}

SmallModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

const ModalS = styled(Modal)`
  text-align: center;
`;

const Title = styled.span`
  font-size: 1.5em;
  font-family: Gotham-Book;
  line-height: 1.2;
  color: ${(props) => props.fontColor || 'rgb(0, 0, 0)'};
  opacity: 0.8;
`;

export default SmallModal;
