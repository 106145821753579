import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import styled from 'styled-components';
import UserInfoFields from '../forms/UserInfoFields';
import {
  deleteUserFamilyMember,
  editUserFamilyMember,
} from '../../actions/profileActions';
import { clearErrors } from '../../actions/homeActions';
import {
  validateFormData,
  formatPhoneNumber,
  sanitizeNames,
  sanitizePhone,
} from '../forms/utils';
import {
  ButtonSmall,
  ButtonSmallSuccess,
  Forms,
  ProfileMemberWrap,
} from '../../elements/profile/ProfileMembers';
import {
  deleteIcon, dateIcon, phoneIcon, mailIcon,
} from '../icons/userIcons';

const Name = styled.div`
  color: ${(props) => props.themeColor || '#424242'};
`;

class ProfileMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /*eslint-disable*/
      firstName: props.firstName,
      lastName: props.lastName,
      birthday: moment(props.birthday, 'YYYY-MM-DD'),
      phone: props.phone,
      email: props.email,
      id: props.patientId,
      patientPmsId: props.patientPmsId,
      relationShip: props.relationShip,
      isEdit: false,
      formMsgErrors: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.editSuccess) {
      this.setState({ isEdit: false });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.phone !== '') {
      this.setState({ phone: formatPhoneNumber(this.state.phone) });
    }
    const formData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      birthday: this.state.birthday,
      phone: this.state.phone,
      email: this.state.email,
    };
    const {
      patientId, token, patientPmsId, relationShip,
    } = this.props;
    if (!Object.entries(this.validateForm(formData)).length > 0) {
      formData.relationShip = relationShip;
      formData.id = this.props.patientId;
      formData.patientPmsId = patientPmsId;
      formData.birthday = moment
        .utc(this.state.birthday, 'MM-DD-YYYY')
        .format('YYYY-MM-DD');
      this.props.dispatch(editUserFamilyMember(formData, token, patientId));
    }
  }

  onChange = (value, name) => {
    let inputValue = value;
    if (name === 'firstName' || name === 'lastName') {
      inputValue = sanitizeNames(value);
    }
    if (name === 'phone') {
      inputValue = sanitizePhone(value);
    }
    if (name === 'phone' && value.length > 9 && sanitizePhone(value)) {
      inputValue = formatPhoneNumber(sanitizePhone(value));
    }
    this.setState({ [name]: inputValue });
    this.setState((prevState) => ({
      formMsgErrors: {
        ...prevState.formMsgErrors,
        [name]: '',
      },
    }));
  };

  displayFormErrors(formElements, errors) {
    Object.keys(formElements).forEach((key) => {
      this.setState((prevState) => ({
        formMsgErrors: {
          ...prevState.formMsgErrors,
          [key]: errors[key] || '',
        },
      }));
    });
  }

  validateForm(formData) {
    const formErrors = validateFormData(formData, 'family');
    this.displayFormErrors(formData, formErrors);
    return formErrors;
  }

  handleDayChange = (birthday) => {
    this.setState((prevState) => ({
      formMsgErrors: {
        ...prevState.formMsgErrors,
        birthday: '',
      },
    }));
    this.setState({ birthday: moment(birthday, 'MM-DD-YYYY', true) });
  };

  checkDateSelect = (birthday) => {
    if (birthday === '') {
      this.setState({
        birthdayMessage: this.props.errors.birthday
          ? this.props.errors.birthday
          : 'This field is required',
      });
    } else {
      this.setState({ birthdayMessage: '' });
    }
  };

  smClose = () => this.setState({ smShow: false });

  editMember = () => {
    this.setState({
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      birthday: moment(this.props.birthday, 'YYYY-MM-DD'),
      phone:
        this.props.phone !== ''
        	? formatPhoneNumber(this.props.phone)
        	: this.props.phone,
      email: this.props.email,
      isEdit: !this.state.isEdit,
    });
  };

  cancelEdit = () => {
    this.setState({
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      birthday: moment(this.props.birthday, 'YYYY-MM-DD'),
      phone: this.props.phone,
      email: this.props.email,
      isEdit: !this.state.isEdit,
      formMsgErrors: {},
    });
    this.props.dispatch(clearErrors());
  };

  deleteMember = () => {
    this.setState({ smShow: true });
  };

  deleteConfirm = () => {
    const { patientId, token, familyMembers } = this.props;
    const index = familyMembers.findIndex((o) => o.patientId === patientId);
    if (index !== -1) {
      familyMembers.splice(index, 1);
    }
    this.props.dispatch(deleteUserFamilyMember(patientId, token));
    this.setState({ smShow: false });
  };

  render() {
    const {
      fontColor,
      themeColor,
      errors,
      firstName,
      lastName,
      phone,
      email,
      photo,
    } = this.props;
    const {
      isEdit, birthday, smShow, formMsgErrors,
    } = this.state;
    const age = moment(birthday, 'MM-DD-YYYY').fromNow(true);
    let editForm;
    if (isEdit) {
      editForm = (
        <ProfileMemberWrap>
          <Forms onSubmit={(e) => this.onSubmit(e)}>
            <UserInfoFields
              data={this.state}
              onChange={this.onChange}
              fontColor={fontColor}
              themeColor={themeColor}
              handleDayChange={this.handleDayChange}
              errors={errors.length ? errors : formMsgErrors}
            />
            <ButtonSmall
              type="button"
              color={fontColor}
              onClick={this.cancelEdit}
            >
              Cancel
            </ButtonSmall>
            <ButtonSmallSuccess color={themeColor} type="submit">
              Save
            </ButtonSmallSuccess>
          </Forms>
        </ProfileMemberWrap>
      );
    } else {
      editForm = null;
    }
    return (
      <>
        <Modal show={smShow} onHide={this.smClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title><div className='delete-title'>Delete Account</div></Modal.Title>
          </Modal.Header>
          <Modal.Body><div>Are you sure you want to delete your family member from your profile?</div></Modal.Body>
          <Modal.Footer>
            <div>
              <ButtonSmallSuccess
                color={themeColor}
                onClick={this.deleteConfirm}
              >
                Yes
              </ButtonSmallSuccess>
              <ButtonSmall color={fontColor} onClick={this.smClose}>
                No
              </ButtonSmall>

            </div>
          </Modal.Footer>
        </Modal>
          <tr>
            {/* <DivMembers>
              <Col xs={4} md={3}>
                {photo ? (
                  <MemberPhoto src={photo} />
                ) : (
                  <MemberPhoto src="/img/photo-upload.png" />
                )}
              </Col>
              <Col xs={8} md={9}>
                <MemberName colorDescription={fontColor}>
                  {firstName}
                  {' '}
                  {lastName}
                </MemberName>
                <MemberDescription colorDescription={fontColor}>
                  {age}
                </MemberDescription>
                <MemberDescription colorDescription={fontColor}>
                  {formatPhoneNumber(phone)}
                </MemberDescription>
                <MemberDescription colorDescription={fontColor}>
                  {email}
                </MemberDescription>
                <ButtonSmall color={fontColor} onClick={this.deleteMember}>
                  Delete
                </ButtonSmall>
              </Col>
            </DivMembers> */}
          <td>
            <Name themeColor={themeColor} className='name'>
            {firstName}
              {' '}
              {lastName}
            </Name>
          </td>
          <td>
            {dateIcon(themeColor || '#424242')}
            {age}
          </td>
          <td>
            {phoneIcon(themeColor || '#424242')}
            {formatPhoneNumber(phone)}
          </td>
          <td>
            {mailIcon(themeColor || '#424242')}
            {email}
          </td>
            <td><ButtonSmall color={fontColor} onClick={this.deleteMember}>
            {deleteIcon(themeColor || '#424242')}
            </ButtonSmall></td>
        </tr>
      </>
    );
  }
}
ProfileMember.propTypes = {
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  birthday: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
    errors: PropTypes.object, //eslint-disable-line
  dispatch: PropTypes.func.isRequired,
  patientId: PropTypes.number.isRequired,
  patientPmsId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  familyMembers: PropTypes.array.isRequired,
  relationShip: PropTypes.number.isRequired,
  photo: PropTypes.string.isRequired,
  editSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontColor: state.homeReducer.clinic.styleOptions.fontColor,
  errors: state.homeReducer.errors,
  familyMembers: state.userReducer.familyMembers,
});

export default withRouter(connect(mapStateToProps)(ProfileMember));
