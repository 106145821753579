import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';

const NavigationStep = styled.nav`
list-style: none;
padding: 3rem 2.5rem;
background-color: #EFF3FF;
  li:last-child{
    margin-bottom: 0;
    &:after{
      display:none;
    }
`;
const ListStep = styled.li`
  width: 100%;
  position: relative;
  margin-bottom: 7.7rem;
  &:after { 
    content: '';
    position: absolute;
    left: 10px;
    top: 30px;
    width: 1px;
    height: 7rem;
    background: #aaaaaa;  
  }
`;

const LinkSteps = styled.div`
  align-items: center;
  display: flex;

  span {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: ${(props) => props.fontColor || '#aaa'};
  }

  &:focus {
    text-decoration: none;
    outline: none;
  }
  div.rounded-step{
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    border-radius: 50%;
    background-color: #aaa;
    position: relative;
    color: #fff;
    text-align: center;
    &:after{
      content: '✓';
      position: absolute;
      width: 20px;
      height: 20px;
      color: #fff;
      background: transparent;
      left: 0;
      border-radius: 50%;
      opacity: 0;
    }
  }

  ${(props) => props.disabled
    && `
    span {
      color: ${props.fontColor || '#aaa'};
      opacity: .2;
    }
  `} &.select {
    div.rounded-step{
      background-color: ${(props) => props.linkHoverColor || '#aaa'};
      &:after{
      background: ${(props) => props.linkHoverColor || '#aaa'};
      opacity: 1;
      }
    }
    span {
      color: ${(props) => props.linkHoverColor || '#aaa'};
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    span {
      font-size: 0.9em;
      display: none;
    }
    &.select {
      span {
        display: block;
        margin-right: 5px;
      }
      > svg {
        margin: 0 10px;
      }
    }
  }
`;

function AppointmentStepsHeaderNav({ location }) {
  const themeColor = useSelector((state) => state.homeReducer.clinic.styleOptions.themeColor);
  const fontColor = useSelector((state) => state.homeReducer.clinic.styleOptions.fontColor);

  return (
    <Row
      style={{
        width: '100%',
        margin: 0,
        backgroundColor: 'rgb(255, 255, 255)',
        height: '100%',
      }}
      className="h-100"
    >
      <NavigationStep>
        <ListStep>
          <LinkSteps
            fontColor={fontColor}
            linkHoverColor={themeColor}
            className={location.pathname === '/services' ? 'select' : null}
          >
            <div className="rounded-step">1</div>
            <span>Services</span>
          </LinkSteps>
        </ListStep>
        <ListStep>
          <LinkSteps
            fontColor={fontColor}
            linkHoverColor={themeColor}
            className={location.pathname === '/providers' ? 'select' : null}
          >
            <div className="rounded-step">2</div>
            <span>Providers</span>
          </LinkSteps>
        </ListStep>
        <ListStep>
          <LinkSteps
            fontColor={fontColor}
            linkHoverColor={themeColor}
            className={location.pathname === '/filter' ? 'select' : null}
          >
            <div className="rounded-step">3</div>
            <span>Appointments Available</span>
          </LinkSteps>
        </ListStep>
        <ListStep>
          <LinkSteps
            fontColor={fontColor}
            linkHoverColor={themeColor}
            className={location.pathname === '/results' || location.pathname === '/success' ? 'select' : null}
          >
            <div className="rounded-step">4</div>
            <span>Confirmation</span>
          </LinkSteps>
        </ListStep>
      </NavigationStep>
    </Row>
  );
}

AppointmentStepsHeaderNav.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(AppointmentStepsHeaderNav);
