import cookie from 'react-cookies';
import {
  getUserField,
  getLocalBoolWithDefault,
  getLocalObject,
  getLocalArray,
  getLocalString,
} from '../../utils/storageGetUtils';

export default {
  authorization: false,
  authorized: false,
  registering: false,
  registered: false,
  isAuthorized: getLocalBoolWithDefault('isAuthorized', false),
  fetchingFamilyMembers: false,
  fetchedFamilyMembers: false,
  editSuccess: false,
  token: cookie.load('token') || getLocalString('token'),
  accountInfo: getLocalObject('accountInfo'),
  familyMembers: getLocalArray('familyMembers'),
  upcomingApps: getLocalObject('upcomingApps'),
  fetchingUpcomingApps: false,
  validateModal: false,
  confirmModal: false,
  userRegistryAccountId: 0,
  validateMethod: getUserField('validateMethod'),
  verifying: false,
  verifyed: false,
  confirming: false,
  confirmed: false,
  registryMessageModal: false,
  wrongConfirmCodeToolTip: false,
  resetPassConfirmModal: false,
  passSuccessMessageModal: false,
  existingAccountMsgModal: false,
};
