import camelcaseKeys from 'camelcase-keys-deep';
import {
  SERVICES_AVAILABLE_REQUEST,
  SERVICES_AVAILABLE_SUCCESS,
  SERVICES_AVAILABLE_FAILURE,
  SELECTED_SERVICE,
  SELECT_SERVICE,
} from '../constants';
import {
  uriWithParams,
  jwtHeaders,
  mapServices,
  handleErrors,
} from '../utils/apiUtils';
import { logout } from './authActions';

export function getServices(token, data) {
  return (dispatch) => {
    dispatch({ type: SERVICES_AVAILABLE_REQUEST });
    fetch(uriWithParams('services', data), {
      method: 'GET',
      headers: jwtHeaders(token),
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        const jsonCamel = camelcaseKeys(json, { deep: true });
        let services = [];
        if (
          jsonCamel.code !== 1001
          && jsonCamel.data.items
          && jsonCamel.data.items.length > 0
        ) {
          services = mapServices(jsonCamel.data.items);
        }
        dispatch({ type: SERVICES_AVAILABLE_SUCCESS, payload: services });
        sessionStorage.setItem('services', JSON.stringify(services));
      })
      .catch((error) => {
        if (error.data.errors.error === 'Token is expired') {
          dispatch(logout(history));
        } else {
          dispatch({ type: SERVICES_AVAILABLE_FAILURE, payload: error });
        }
      });
  };
}

export function selectService(data) {
  return (dispatch) => {
    sessionStorage.setItem('selectedService', JSON.stringify(data));
    sessionStorage.setItem('selectedServiceId', data.id);
    dispatch({ type: SELECTED_SERVICE, payload: data });
    dispatch({ type: SELECT_SERVICE, payload: data });
  };
}
