import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'core-js';
import TagManager from 'react-gtm-module';
import { Container } from 'react-bootstrap';
import getClinicId from '../utils/getIdClinic';
import Page from '../components/page/Page';
import Loading from '../components/Loading';
import { utmParameters } from '../actions/authActions';

const clinicId = getClinicId();

function Layout({
  fetching,
  fetched,
  isAuthorized,
  clinic,
  messageNotificationError,
  messageNotificationErrorOuter,
  dispatch,
}) {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has('_ga') || searchParams.has('campaign_id')) {
      const search = [];
      if (searchParams.has('_ga')) {
        search.push(`_ga=${searchParams.get('_ga')}`);
      }
      if (searchParams.has('campaign_id')) {
        search.push(`campaign_id=${searchParams.get('campaign_id')}`);
      }
      sessionStorage.setItem('_ga', `?${search.join('&')}`);
    } else {
      sessionStorage.removeItem('_ga');
    }
    let utmContent = null;
    if (sessionStorage.getItem('utmContent')) {
      utmContent = sessionStorage.getItem('utmContent');
      dispatch(utmParameters(JSON.parse(utmContent)));
    }
    if (searchParams.has('utm_content')) {
      utmContent = {
        key: 'utmContent',
        value: searchParams.get('utm_content'),
      };
      dispatch(utmParameters(utmContent));
      sessionStorage.setItem('utmContent', JSON.stringify(utmContent));
    }
    let utmTerm = null;
    if (sessionStorage.getItem('utmTerm')) {
      utmTerm = sessionStorage.getItem('utmTerm');
      dispatch(utmParameters(JSON.parse(utmTerm)));
    }
    if (searchParams.has('utm_term')) {
      utmTerm = { key: 'utmTerm', value: searchParams.get('utm_term') };
      dispatch(utmParameters(utmTerm));
      sessionStorage.setItem('utmTerm', JSON.stringify(utmTerm));
    }
    let utmMedium = null;
    if (sessionStorage.getItem('utmMedium')) {
      utmMedium = sessionStorage.getItem('utmMedium');
      dispatch(utmParameters(JSON.parse(utmMedium)));
    }
    if (searchParams.has('utm_medium')) {
      utmMedium = { key: 'utmMedium', value: searchParams.get('utm_medium') };
      dispatch(utmParameters(utmMedium));
      sessionStorage.setItem('utmMedium', JSON.stringify(utmMedium));
    }
    let utmSource = null;
    if (sessionStorage.getItem('utmSource')) {
      utmSource = sessionStorage.getItem('utmSource');
      dispatch(utmParameters(JSON.parse(utmSource)));
    }
    if (searchParams.has('utm_source')) {
      utmSource = { key: 'utmSource', value: searchParams.get('utm_source') };
      dispatch(utmParameters(utmSource));
      sessionStorage.setItem('utmSource', JSON.stringify(utmSource));
    }
    let utmCampaign = null;
    if (sessionStorage.getItem('utmCampaign')) {
      utmCampaign = sessionStorage.getItem('utmCampaign');
      dispatch(utmParameters(JSON.parse(utmCampaign)));
    }
    if (searchParams.has('utm_campaign')) {
      utmCampaign = {
        key: 'utmCampaign',
        value: searchParams.get('utm_campaign'),
      };
      dispatch(utmParameters(utmCampaign));
      sessionStorage.setItem('utmCampaign', JSON.stringify(utmCampaign));
    }
  }, [dispatch]);

  if (fetching) {
    return (
      <Loading
        height={`${window.innerHeight}px`}
        innerText="loading..."
        themeColor="rgb(0, 0, 0)"
      />
    );
  }

  if (fetched) {
    if (!window.dataLayer) {
      const tagManagerArgs = {
        gtmId: clinic.clinicInfo.googleTagManagerId,
        dataLayer: {
          clientId: clinicId,
        },
      };
      TagManager.initialize(tagManagerArgs);
    }

    const setBodyFontSize = (fontSize) => {
      document.body.style.fontSize = `${fontSize}px`;
    };

    setBodyFontSize(clinic.styleOptions.font);
    return (
      <Container fluid>
        <Page clinic={clinic} isAuthorized={isAuthorized} />
      </Container>
    );
  }

  return (
    <Loading
      height={`${window.innerHeight}px`}
      innerText={messageNotificationError}
      outerText={messageNotificationErrorOuter}
      themeColor="rgb(0, 0, 0)"
    />
  );
}

Layout.propTypes = {
  fetching: PropTypes.bool.isRequired,
  fetched: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  clinic: PropTypes.shape({
    styleOptions: PropTypes.shape({
      themeColor: PropTypes.string.isRequired,
      fontColor: PropTypes.string.isRequired,
      font: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
    }),
    clinicInfo: PropTypes.shape({
      title: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      email: PropTypes.array.isRequired,
      fbLink: PropTypes.string.isRequired,
      googleLink: PropTypes.string.isRequired,
      twitterLink: PropTypes.string.isRequired,
      youtubeLink: PropTypes.string.isRequired,
      linkedinLink: PropTypes.string.isRequired,
      googleTagManagerId: PropTypes.string.isRequired,
    }),
    questionOptions: PropTypes.shape({
      enabled: PropTypes.bool.isRequired,
      questionText: PropTypes.string,
      possibleAnswers: PropTypes.arrayOf(PropTypes.string),
    }),
    widgetQuestions: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(PropTypes.string), // Optional based on question type
    })),
  }).isRequired,
  messageNotificationError: PropTypes.string.isRequired,
  messageNotificationErrorOuter: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};
Layout.defaultProps = {
  messageNotificationErrorOuter: '',
};

const mapStateToProps = (state) => ({
  clinic: state.homeReducer.clinic,
  fetching: state.homeReducer.fetching,
  fetched: state.homeReducer.fetched,
  messageNotificationError: state.homeReducer.messageNotificationError,
  messageNotificationErrorOuter:
  state.homeReducer.messageNotificationErrorOuter,
  isAuthorized: state.userReducer.isAuthorized,
});

export default withRouter(connect(mapStateToProps)(Layout));
