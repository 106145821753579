import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import AppointmentStepsHeaderNav from './AppointmentStepsHeaderNav';
import StepHeader from './AppointmentStepsHeader';
import FormElem from '../forms/FormElem';
import Button from '../forms/Button';
import {
  sendRequestAppointment,
  appReqErrMsgModalClose,
} from '../../actions/appointmentActions';
import Loading from '../Loading';
import { getSessionString } from '../../utils/storageGetUtils';
import {
  Root,
} from '../../elements/appointmentSteps/Results';
import { LeftArrow } from '../styles';

const appointmentTitle = [
  'Request Made By:',
  'Reason:',
  'Provider:',
  'Opening Selected:',
];

const id = [
  'patient',
  'reason',
  'provider',
  'opening',
  'hearUs',
];

class Result extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createdFor: getSessionString('createdFor')
        ? getSessionString('createdFor')
        : props.patientId,
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {
      selectedTime,
      token,
      history,
      patientId,
      slotId,
      locationId,
      selectedService,
      request,
      hashBid,
      widgetQuestions,
    } = this.props;
    const questionAnswer = [];
    for (let i = 0; i < widgetQuestions.length; i += 1) {
      const { question } = widgetQuestions[i];
      const answer = sessionStorage.getItem(question);
      questionAnswer.push({ question, answer });
    }
    const requestAppointment = {
      serviceId: request.serviceId,
      providerId: request.providerId,
      providerPmsId: request.providerPmsId,
      providerName: request.providerName,
      patientId: request.patientId,
      patientPmsId: null,
      date: moment.utc(selectedTime, 'YYYY-MM-DD hh:mm a').format(),
      forUser: this.state.createdFor !== '' ? this.state.createdFor : patientId,
      questionAnswer,
      slotId,
      locationId,
      duration: selectedService.duration,
      hashBid,
    };
    this.props.dispatch(
      sendRequestAppointment(token, requestAppointment, history),
    );
  };

  onChange = (value, name) => {
    sessionStorage.setItem(name, value);
  };

  mClose = () => {
    const { history } = this.props;
    this.props.dispatch(appReqErrMsgModalClose(history));
  };

  renderContent() {
    const {
      firstName,
      selectedService,
      selectedTime,
      request,
    } = this.props;
    const date = new Date(selectedTime.replace(/-/g, '/'));
    const time = moment(date, 'YYYY-MM-DD hh:mm:ss');
    const { name, duration } = selectedService;
    const pName = request.providerName;
    return appointmentTitle.map((title, index) => {
      let content = '';
      switch (id[index]) {
        case 'patient':
          content = `${firstName}`;
          break;
        case 'reason':
          content = `${name} (${duration} min duration)`;
          break;
        case 'provider':
          content = `${pName}`;
          break;
        case 'opening':
          content = `${time.format('MMMM DD YYYY')}, ${time.format('hh:mm a')}`;
          break;
        default:
          break;
      }

      return (
        <div className="my-4" style={{ width: '80%' }}>
          <Row key={Math.random()}>
            <Col md={3} lg={3} sm={12}>
              <p className="m-0 fw-bold">{title}</p>
            </Col>
            <Col md={7} lg={7} sm={12}>
              <p className="m-0">{content}</p>
            </Col>
          </Row>
          <hr />
        </div>
      );
    });
  }

  renderQuestion(widgetQuestions) {
    return widgetQuestions.map((question) => {
      const { type, question: questionText, answers } = question;
      if (type === 'dropdown') {
        const initialAnswer = sessionStorage.getItem(questionText) || 'No answer selected';
        const dropdownValues = ['Please select an answer.', ...answers];
        if (sessionStorage.getItem(questionText) === null) {
          sessionStorage.setItem(questionText, answers[0]);
        }
        return (
          <div key={questionText} className="my-4">
            <Row>
              <Col md={3} lg={3} sm={12}>
                <p className="m-0 fw-bold text-wrap text-break">{questionText}</p>
              </Col>
              <Col md={7} lg={7} sm={12}>
                <FormElem
                  valid={[]}
                  onChange={(value) => this.onChange(questionText, value)}
                  name={questionText}
                  type="Dropdown"
                  dropdownValues={dropdownValues}
                  value={initialAnswer}
                />
              </Col>
            </Row>
            <hr />
          </div>
        );
      }
      return (
        <div key={questionText} className="my-4">
          <Row>
            <Col md={3} lg={3} sm={12}>
              <p className="m-0 fw-bold">{questionText}</p>
            </Col>
            <Col md={7} lg={7} sm={12}>
              <FormElem
                valid={[]}
                onChange={(questionText, value) => this.onChange(questionText, value)}
                name={questionText}
                type="Textarea"
                placeholder=""
                maxLength={5000}
              />
            </Col>
          </Row>
          <hr />
        </div>
      );
    });
  }

  renderErrorModal() {
    const { appReqErrMsgModal } = this.props;
    return (
      <Modal
        show={appReqErrMsgModal}
        onHide={this.mClose}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation failed!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The system was unable to confirm your appointment,
          please select a different schedule.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.mClose}>Close</Button>
        </Modal.Footer>
      </Modal>

    );
  }

  render() {
    const {
      themeColor,
      fetchingSend,
      fontColor,
      widgetQuestions,
    } = this.props;
    if (fetchingSend) {
      return (
        <Root>
          <Col xs={12}>
            <Loading
              height="150px"
              innerText="loading..."
              themeColor={themeColor}
            />
          </Col>
        </Root>
      );
    }
    const _ga = sessionStorage.getItem('_ga')
      ? sessionStorage.getItem('_ga')
      : '';
    return (
      <div className="steps-container">
        <Row>
          {this.renderErrorModal()}
          <Col md={3} lg={3} sm={12}>
            <AppointmentStepsHeaderNav />
          </Col>
          <Col md={9} lg={9} sm={12}>
            <div className="main-content">
              <StepHeader
                title="Summary Confirmation"
                description="Please review the details and click confirm to schedule your appointment. You will receive a confirmation email shortly."
              />
              <div className="my-2">
                <form action="" onSubmit={this.onSubmit}>
                  {this.renderContent(themeColor, fontColor)}
                  <div className="me-4" style={{ width: '80%' }}>
                    {this.renderQuestion(widgetQuestions)}
                  </div>
                  <hr style={{ margin: '40px 0' }} />
                  <Row className="justify-content-between align-items-center">
                    <Col md={6} lg={6} sm={12}>
                      <LeftArrow
                        color={themeColor}
                        className="back-button"
                        to={`/filter${_ga}`}
                      >
                        back
                      </LeftArrow>
                    </Col>
                    <Col md={6} lg={6} sm={12} className="text-end">
                      <Button className="next-button" type="submit">Confirm</Button>
                    </Col>
                  </Row>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

Result.propTypes = {
  firstName: PropTypes.string.isRequired,
  selectedService: PropTypes.object.isRequired,
  selectedTime: PropTypes.string.isRequired,
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  fetchingSend: PropTypes.bool.isRequired,
  widgetQuestions: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(PropTypes.string), // Optional based on question type
  })).isRequired,
  patientId: PropTypes.number.isRequired,
  slotId: PropTypes.string.isRequired,
  locationId: PropTypes.string.isRequired,
  appReqErrMsgModal: PropTypes.bool.isRequired,
  request: PropTypes.object.isRequired,
  hashBid: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  token: state.userReducer.token,
  patientId: state.userReducer.patientId,
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontColor: state.homeReducer.clinic.styleOptions.fontColor,
  firstName: state.userReducer.accountInfo.firstName,
  familyMembers: state.userReducer.familyMembers,
  selectedService: state.appointmentReducer.selectedService,
  selectedTime: state.appointmentReducer.selectedTime,
  fetchingSend: state.appointmentReducer.fetchingSend,
  questionEnabled: state.homeReducer.clinic.questionOptions.enabled,
  widgetQuestions: state.homeReducer.clinic.widgetQuestions,
  slotId: state.appointmentReducer.slotId,
  locationId: state.appointmentReducer.locationId,
  appReqErrMsgModal: state.appointmentReducer.appReqErrMsgModal,
  request: state.appointmentReducer.request,
  hashBid: state.appointmentReducer.request.hashBid,
});

export default withRouter(connect(mapStateToProps)(Result));
