import camelcaseKeys from 'camelcase-keys-deep';
import {
  SELECT_SERVICE,
  SELECT_PROVIDER,
  SELECT_DATE_FROM,
  SELECT_DATE_TO,
  SELECT_MORNING,
  SELECT_NOON,
  SELECT_EVENING,
  FETCH_OPENING_TIME,
  FETCH_OPENING_TIME_FULFILLED,
  FETCH_OPENING_TIME_REJECTED,
  SELECT_TIME,
  FETCH_SEND_APPOINTMENT,
  SET_DAYS_VIEW,
  FETCH_SEND_APPOINTMENT_FULFILLED,
  FETCH_SEND_APPOINTMENT_REJECTED,
  SELECT_SLOT_TIME,
  APP_REQ_ERR_MSG_HIDE,
  RESET_APPT_REQUEST,
  FETCH_SEND_APPOINTMENT_FULFILLED_INACTIVE_PATIENT,
  SKIP_PROVIDER,
} from '../constants';
import {
  uri, jwtHeaders, getRequestValues, sortOpenings, handleErrors,
} from '../utils/apiUtils';
import { logout } from './authActions';

export function selectService(
  selectedService,
  selectedServiceId,
  selectedServiceProviders,
) {
  sessionStorage.setItem('selectedService', JSON.stringify(selectedService));
  sessionStorage.setItem('selectedServiceId', selectedServiceId);
  sessionStorage.setItem(
    'selectedServiceProviders',
    JSON.stringify(selectedServiceProviders),
  );
  return {
    type: SELECT_SERVICE,
    payload: { selectedService, selectedServiceId, selectedServiceProviders },
  };
}

export function selectProvider(provider, history) {
  sessionStorage.setItem('selectedProviderId', provider.id);
  return (dispatch) => {
    dispatch({ type: SELECT_PROVIDER, payload: provider });
    history.push({
      pathname: '/filter',
      search: sessionStorage.getItem('_ga'),
    });
  };
}

export function skipProvider(data) {
  return (dispatch) => {
    dispatch({ type: SKIP_PROVIDER, payload: data });
  };
}

export function selectDateFrom(date) {
  sessionStorage.setItem('dateFrom', date);
  return {
    type: SELECT_DATE_FROM,
    payload: date,
  };
}

export function selectDateTo(date) {
  sessionStorage.setItem('dateTo', date);
  return {
    type: SELECT_DATE_TO,
    payload: date,
  };
}

export function selectMorning(data) {
  return {
    type: SELECT_MORNING,
    payload: data,
  };
}

export function selectNoon(data) {
  return {
    type: SELECT_NOON,
    payload: data,
  };
}

export function selectEvening(data) {
  return {
    type: SELECT_EVENING,
    payload: data,
  };
}

// Review function for later use. Arian note.
// This is for better handling token expiration. Aug/29/19
// const tokenExpired = function (error, dispatch) {
//   if (error.Errors && error.Errors.exception === 'Token is expired') {
//     dispatch({ type: USER_LOGOUT_SUCCESS, payload: 'Token is expired' });
//     window.sessionStorage.clear();
//     window.localStorage.clear();
//   }
// };

export function getOpeningTimes(request, token, history) {
  const data = getRequestValues(request);
  const body = JSON.stringify({
    service_id: data.serviceId,
    provider_id: data.providerId,
    provider_pms_id: data.pmsId,
    duration: `${data.duration}`,
    start_date: data.startDate,
    end_date: data.endDate,
    am: data.am,
    pm: data.noon,
    late_pm: data.evening,
    hbid: `${data.hashBid}`,
    patient_id: data.patientId,
  });
  return (dispatch) => {
    dispatch({ type: FETCH_OPENING_TIME });
    fetch(
      uri('openings'),
      {
        method: 'POST',
        headers: jwtHeaders(token),
        body,
      },
    )
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        const jsonCamel = camelcaseKeys(json, { deep: true });
        const openings = (jsonCamel.data.item.openings && jsonCamel.data.item.openings.length > 0)
          ? sortOpenings(jsonCamel.data.item.openings) : [];
        dispatch({ type: FETCH_OPENING_TIME_FULFILLED, payload: openings });
        sessionStorage.setItem('openings', JSON.stringify(openings));
        history.push({
          search: sessionStorage.getItem('_ga'),
        });
      })
      .catch((error) => {
        if (error.data.errors.error === 'Token is expired') {
          dispatch(logout(history));
        } else {
          dispatch({ type: FETCH_OPENING_TIME_REJECTED, payload: error });
        }
      });
  };
}

export function selectTime(time) {
  sessionStorage.setItem('selectedTime', time);
  return (dispatch) => {
    dispatch({ type: SELECT_TIME, payload: time });
  };
}

export function selectSlotTime(data, history) {
  sessionStorage.setItem('selectedSlotTimeButton', JSON.stringify(data));
  sessionStorage.setItem('selectedSlotTime', data);
  return (dispatch) => {
    dispatch({ type: SELECT_SLOT_TIME, payload: data });
    history.push({
      search: sessionStorage.getItem('_ga'),
    });
  };
}

export function sendRequestAppointment(token, data, history) {
  const body = JSON.stringify({
    Appointment: {
      slot_id: data.slotId,
      location_id: data.locationId,
      patient_id: data.patientId,
      patient_pms_id: data.patientPmsId,
      service_id: data.serviceId,
      provider_id: data.providerId,
      provider_pms_id: data.providerPmsId,
      comment: data.specificComment,
      date: data.date,
      question: data.question,
      answers: data.questionAnswer,
      duration: data.duration,
      hbid: data.hashBid,
    },
  });
  return (dispatch) => {
    dispatch({ type: FETCH_SEND_APPOINTMENT });
    fetch(
      uri('appointments'),
      {
        method: 'POST',
        headers: jwtHeaders(token),
        body,
      },
    ).then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        // TODO Update the saving of the sessionStorage info

        if (json.code !== 1001) {
          const appointmentRequest = camelcaseKeys(json, {
            deep: true,
          });
          dispatch({
            type: FETCH_SEND_APPOINTMENT_FULFILLED,
            payload: {
              comment: data.specificComment,
              approveRequested: appointmentRequest.data.item.approveRequested,
            },
          });
        } else {
          dispatch({
            type: FETCH_SEND_APPOINTMENT_FULFILLED_INACTIVE_PATIENT,
            payload: { comment: data.specificComment },
          });
        }
        history.push({
          pathname: '/success',
          search: sessionStorage.getItem('_ga'),
        });
      })
      .catch((error) => {
        if (error.data.errors.error === 'Token is expired') {
          dispatch(logout(history));
        } else {
          dispatch({ type: FETCH_SEND_APPOINTMENT_REJECTED, payload: error });
        }
      });
  };
}

export function setDaysView(count) {
  return (dispatch) => {
    dispatch({ type: SET_DAYS_VIEW, payload: count });
  };
}

export function appReqErrMsgModalClose(history) {
  return function (dispatch) {
    dispatch({ type: APP_REQ_ERR_MSG_HIDE });
    history.push({
      pathname: '/filter',
      search: sessionStorage.getItem('_ga'),
    });
  };
}

export function resetApptRequest() {
  return (dispatch) => {
    dispatch({ type: RESET_APPT_REQUEST });
  };
}
