import {
  FETCH_APPT_APPROVAL_REQUEST,
  FETCH_APPT_APPROVAL_FULFILLED,
  APPT_APPROVAL_ERROR,
} from '../constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_APPT_APPROVAL_REQUEST: {
      return {
        ...state,
        fetching: true,
      };
    }

    case FETCH_APPT_APPROVAL_FULFILLED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        apptConfirmed: action.payload,
      };
    }

    case APPT_APPROVAL_ERROR: {
      return {
        ...state,
        fetching: false,
        fetched: false,
        errors: action.payload,
      };
    }

    default:
      return state;
  }
};
