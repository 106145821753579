import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';
import homeReducer from './homeReducer';
import appointmentReducer from './appointmentReducer';
import updatePasswordReducer from './updatePasswordReducer';
import userReducer from './userReducer';
import serviceReducer from './serviceReducer';
import providerReducer from './providerReducer';
import appointmentApprovalReducer from './appointmentApprovalReducer';

export default combineReducers({
  homeReducer,
  userReducer,
  appointmentReducer,
  updatePasswordReducer,
  notifications,
  serviceReducer,
  providerReducer,
  appointmentApprovalReducer,
});
