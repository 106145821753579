import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StarRatingComponent from 'react-star-rating-component';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { PageContainer, LeftArrow } from '../../styles';
import Loading from '../../Loading';

const Root = styled(PageContainer)`
  padding: 40px 40px;
  align-items: flex-start;

  hr {
    margin-bottom: 45px;
  }

  @media (max-width: 991px) {
    padding: 40px 0 20px;

    hr {
      margin-bottom: 25px;
      margin-top: 50px;
    }
  }
`;

const Avatar = styled.div`
  height: 80px;
  width: 80px;
  background-color: ${(props) => props.color || 'rgb(31, 130, 224)'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const AvatarUser = styled(Avatar)`
  width: 120px;
  height: 120px;
  > img {
    border: 1px solid ${(props) => props.color || 'rgb(220, 220, 220)'};
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: block;
    position: relative;
    z-index: 1;

    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 767px) {
    margin: 20px auto;
  }
`;

const AvatarTitle = styled.p`
  font-size: 1.4em;
  color: rgb(255, 255, 255);
  font-family: Gotham-Book;
  margin: 0;
  text-transform: uppercase;
`;

const NameReviews = styled.h4`
  font-family: Gotham-Book;
  color: ${(props) => props.color || 'rgb(31, 130, 224)'};
  margin: 0;

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const Rating = styled.div`
  margin: 0 -15px;
  text-align: right;
`;

const StarRatingComponentS = styled(StarRatingComponent)`
  display: flex !important;
  justify-content: flex-start;
  flex-direction: row-reverse;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const SpecialityReviews = styled.h5`
  font-family: Gotham-Book;
  color: ${(props) => props.color || 'rgb(31, 130, 224)'};
  margin: 0;
  opacity: 0.4;

  @media (max-width: 767px) {
    text-align: center;
    margin: 5px 0 15px;
  }
`;

const FromReviews = styled.p`
  font-family: Gotham-Book;
  margin: 20px 0;

  @media (max-width: 767px) {
    margin: -15px 0 15px;
  }
`;

const Description = styled.div`
  font-family: Gotham-Book;
  padding: 0 0 50px 15px;
  border-bottom: 1px solid rgb(238, 238, 238);

  @media (max-width: 767px) {
    padding-bottom: 20px;
    margin: 0 -15px;
  }
`;

const DescriptionProvider = styled.div`
  font-family: Gotham-Book;
  padding: 0px 15px 0;
  position: relative;
  top: -10px;
  > div {
    display: block;
    padding: 20px;
    background: -moz-linear-gradient(
      25deg,
      rgba(0, 0, 0, 0) 55%,
      rgba(248, 248, 248, 1) 100% /* ${(props) => props.fontColor} */
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      25deg,
      rgba(0, 0, 0, 0) 55%,
      rgba(248, 248, 248, 1) 100% /* ${(props) => props.fontColor} */
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      25deg,
      rgba(0, 0, 0, 0) 55%,
      rgba(248, 248, 248, 1) 100% /* ${(props) => props.fontColor} */
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#00000000', endColorstr='#1e5799',GradientType=1
    ); /* IE6-9 fallback on horizontal gradient */
  }
  hr {
    margin: 50px auto;
  }

  @media (max-width: 767px) {
    top: 0;
    margin: 0 -15px;

    hr {
      margin: 10px auto 30px;
    }
  }
`;

const LeftArrowS = styled.div`
  position: absolute;
  width: 85px;
  top: 25px;
  z-index: 1;

  a {
    color: ${(props) => props.fontColor};
    width: 100%;

    @media (max-width: 991px) {
      font-size: 1.2em;
    }
  }

  a:focus,
  a:hover {
    color: ${(props) => props.colorsucses || 'rgba(0, 0, 0, .8)'};
  }
`;

const FlexRowCenter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const RowReview = styled(Row)`
  padding-top: 50px;
  &:last-child {
    ${Description} {
      border-bottom: none;
    }
  }

  @media (max-width: 767px) {
    padding-top: 20px;
    margin: 0 -15px;
  }
`;

class Review extends Component {
  constructor() {
    super();
    this.state = {
      /*eslint-disable*/
      rating: 0,
      /* eslint-enable */
    };
  }

  renderReview = (reviewUser, reviewText, reviewRating, themeColor, i) => {
    const reviewUserFL = reviewUser.split(' ');
    const firstN = reviewUserFL[0][0];
    let lastN;
    /*eslint-disable*/
    if (reviewUserFL.length > 1) {
      lastN = reviewUserFL[1][0];
    } else {
      lastN = '';
    }
    /* eslint-enable */
    return (
      <RowReview key={`${reviewUser}${i}`}>
        <Col xs={12} sm={10} smOffset={1} md={8} mdoffset={2}>
          <Col xs={12} sm={2}>
            <Avatar color={themeColor}>
              <AvatarTitle>{`${firstN} ${lastN}`}</AvatarTitle>
            </Avatar>
          </Col>
          <Col xs={12} sm={10}>
            <Col xs={12} sm={8}>
              <NameReviews color={themeColor}>{reviewUser}</NameReviews>
            </Col>
            <Col xs={12} sm={4} smOffset={0}>
              <StarRatingComponentS
                name="rate"
                starCount={5}
                value={reviewRating}
                starColor={themeColor}
                emptyStarColor="rgb(231, 231, 231)"
                //                    onStarClick={e => this.onStarClick(e)}
                editing={false}
              />
            </Col>
          </Col>
          <Col xs={12} sm={10} smOffset={2}>
            <Description>
              <p>{reviewText}</p>
            </Description>
          </Col>
        </Col>
      </RowReview>
    );
  };

  renderReviewProviders = (
    providerReviews,
    themeColor,
    reviewFetching,
    reviewFetched,
  ) => {
    let newFetched = reviewFetched;
    if (reviewFetching) {
      return (
        <Root>
          <Loading innerText="Loading..." themeColor={themeColor} />
        </Root>
      );
    }
    if (sessionStorage.getItem('selectedProviderReviews') !== []) {
      newFetched = true;
    }
    if (newFetched && providerReviews.length > 0) {
      return (
        <div>
          {providerReviews.map((review, i) => this.renderReview(
            review.author,
            review.text,
            review.rating,
            themeColor,
            i,
          ))}
        </div>
      );
    }
    return null;
  };

  renderProviderAvatar = (provider, fontColor) => {
    if (provider.photo) {
      return <img color={fontColor} alt="" src={provider.photo} />;
    }
    return (
      <AvatarTitle>
        {`${provider.firstName[0]} ${provider.lastName[0]}`}
      </AvatarTitle>
    );
  };

  renderProviderInfo = (provider, themeColor, fontColor) => (
    <Row>
      <Col xs={12} sm={10} smOffset={1} md={8} mdoffset={2}>
        <Col xs={12}>
          <FlexRowCenter>
            <Col xsHidden sm={3}>
              <AvatarUser color={themeColor}>
                {this.renderProviderAvatar(provider, fontColor)}
              </AvatarUser>
            </Col>
            <Col xsHidden sm={6}>
              <NameReviews color={themeColor}>
                {`${provider.firstName} ${provider.lastName}`}
              </NameReviews>
              <SpecialityReviews color={themeColor}>
                {`${provider.speciality}`}
              </SpecialityReviews>
            </Col>
            <Col xsHidden sm={3}>
              {provider.rating > 0 ? (
                <Rating>
                  <StarRatingComponentS
                    name="rate"
                    starCount={5}
                    value={parseFloat(provider.rating)}
                    starColor={themeColor}
                    emptyStarColor="rgb(231, 231, 231)"
                    editing={false}
                  />
                  <FromReviews>
                    {provider.reviewsCount > 0
                      ? `${provider.reviewsCount} (Reviews)`
                      : null}
                  </FromReviews>
                </Rating>
              ) : null}
            </Col>
            <Col xs={12} smHidden mdHidden lgHidden>
              <AvatarUser color={themeColor}>
                {this.renderProviderAvatar(provider, fontColor)}
              </AvatarUser>
            </Col>
            {provider.rating > 0 ? (
              <Col
                xs={12}
                smHidden
                mdHidden
                lgHidden
                style={{ textAlign: 'center' }}
              >
                <StarRatingComponentS
                  name="rate"
                  starCount={5}
                  value={parseFloat(provider.rating)}
                  starColor={themeColor}
                  emptyStarColor="rgb(231, 231, 231)"
                  editing={false}
                />
                <FromReviews>
                  {provider.reviewsCount > 0
                    ? `${provider.reviewsCount} (Reviews)`
                    : null}
                </FromReviews>
              </Col>
            ) : null}
            <Col xs={12} smHidden mdHidden lgHidden>
              <NameReviews color={themeColor}>
                {`${provider.firstName} ${provider.lastName}`}
              </NameReviews>
              <SpecialityReviews color={themeColor}>
                {`${provider.speciality}`}
              </SpecialityReviews>
            </Col>
          </FlexRowCenter>
        </Col>
      </Col>
    </Row>
  );

  renderProviderDescription = (biography, fontColor) => (
    <Row>
      <Col xs={12} sm={10} smOffset={1} md={8} mdoffset={2}>
        <DescriptionProvider fontColor={fontColor}>
          <div>{biography !== '' ? biography : 'N / A'}</div>
          {biography !== '' ? <hr /> : null}
        </DescriptionProvider>
      </Col>
    </Row>
  );

  render() {
    const {
      themeColor,
      fontColor,
      selectedProviderForReview,
      reviewFetching,
      reviewFetched,
      selectedProviderReviews,
    } = this.props;
    const _ga = sessionStorage.getItem('_ga')
      ? sessionStorage.getItem('_ga')
      : '';
    if (!selectedProviderForReview) {
      return <Redirect to={`/providers${_ga}`} />;
    }
    return (
      <Root>
        <Col xs={12}>
          <Row>
            <Col xs={4} sm={2}>
              <LeftArrowS fontColor={fontColor} colorsucses={themeColor}>
                <LeftArrow to={`/providers${_ga}`}>back</LeftArrow>
              </LeftArrowS>
            </Col>
          </Row>
          {this.renderProviderInfo(
            selectedProviderForReview,
            themeColor,
            fontColor,
          )}
          {this.renderProviderDescription(
            selectedProviderForReview.biography,
            fontColor,
          )}
          {selectedProviderForReview.reviewsEnabled
            ? this.renderReviewProviders(
              selectedProviderReviews,
              themeColor,
              reviewFetching,
              reviewFetched,
            )
            : null}
          <Row>
            <Col xs={12} sm={8} smOffset={2}>
              <Col xs={12}>
                <DescriptionProvider fontColor={fontColor}>
                  {selectedProviderForReview.biography ? <hr /> : null}
                </DescriptionProvider>
              </Col>
            </Col>
          </Row>
        </Col>
      </Root>
    );
  }
}
Review.propTypes = {
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  selectedProviderForReview: PropTypes.object.isRequired,
  reviewFetching: PropTypes.bool.isRequired,
  reviewFetched: PropTypes.bool.isRequired,
  selectedProviderReviews: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontColor: state.homeReducer.clinic.styleOptions.fontColor,
  selectedProviderForReview: state.appointmentReducer.selectedProviderForReview,
  selectedProviderReviews: state.appointmentReducer.selectedProviderReviews,
  selectedServiceProviders: state.appointmentReducer.selectedServiceProviders,
  reviewFetching: state.appointmentReducer.reviewFetching,
  reviewFetched: state.appointmentReducer.reviewFetched,
  token: state.userReducer.token,
  CID: state.homeReducer.CID,
  activePageReview: state.appointmentReducer.activePageReview,
});

Review.defaultProps = {
  activePageReview: 1,
};

export default withRouter(connect(mapStateToProps)(Review));
