import cookie from 'react-cookies';
import 'core-js';
import getClinicId from './getIdClinic';
import { getParams, transformDate } from './openingUtils';
// eslint-disable-next-line no-unused-vars
import { hostProtocol, hostIp, hostPort } from '../store/api';

const clinicId = getClinicId();
const baseUri = `${hostProtocol}://${hostIp}`;
// const noCorsPrefix = 'https://cors-anywhere.herokuapp.com/';

export const handleErrors = (response) => {
  if (!response.ok) {
    return response.json().then((json) => {
      throw json;
    });
  }
  return response;
};

export const handleLoginErrors = (response) => getErrorResponse(response, 'login');

export const handleRegisterErrors = (response) => getErrorResponse(response, 'registry');

export const handleValidateErrors = (response) => getErrorResponse(response, 'validate');

export const handleConfirmErrors = (response) => getErrorResponse(response, 'confirm');

export const handleResetPassErrors = (response) => getErrorResponse(response, 'reset-pass');

export const handleUpdatePassErrors = (response) => getErrorResponse(response, 'update-pass');

export const getErrorResponse = (response, process) => {
  if (response.status >= 400) {
    return response.json().then((json) => {
      const errors = generateErrorMsg(json.data.errors, process);
      const newJson = {
        type: json.type,
        data: {
          errors,
        },
      };
      throw newJson;
    });
  }
  return response;
};

export const headers = new Headers({
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
  'X-BID': clinicId,
});

export const jwtHeaders = (token) => new Headers({
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
  'X-BID': clinicId,
  Authorization: `Bearer ${token}`,
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  Pragma: 'no-cache',
});

export const uri = (endpoint) => {
  let endPointPath;
  switch (endpoint) {
    case 'login':
      endPointPath = '/widget/account/login';
      break;
    case 'register':
      endPointPath = '/widget/account/register';
      break;
    case 'validate-request':
      endPointPath = '/widget/account/validate-request';
      break;
    case 'register-confirm':
      endPointPath = '/widget/account/register-confirm';
      break;
    case 'reset-password':
      endPointPath = '/widget/account/reset-request';
      break;
    case 'reset-password-confirm':
      endPointPath = '/widget/account/reset-confirm';
      break;
    case 'settings':
      endPointPath = '/widget/settings';
      break;
    case 'profile':
      endPointPath = '/widget/family-members';
      break;
    case 'account':
      endPointPath = '/widget/account';
      break;
    case 'appointments':
      endPointPath = '/widget/appointments';
      break;
    case 'openings':
      endPointPath = '/widget/openings';
      break;
    case 'upcoming-apps':
      endPointPath = '/widget/account/appointments';
      break;
    case 'practices':
      endPointPath = '/widget/practices';
      break;
    default:
      break;
  }

  return baseUri + endPointPath;
};

export const uriWithParams = (endpoint, data) => {
  let endPointPath;
  switch (endpoint) {
    case 'family-members':
      endPointPath = '/widget/family-members';
      break;
    case 'profile-option':
      endPointPath = '/widget/family-members';
      break;
    case 'services':
      endPointPath = '/widget/services';
      break;
    case 'providers':
      endPointPath = '/widget/providers';
      break;
    case 'approval':
      endPointPath = '/appointments/approval';
      break;
    default:
      break;
  }

  return baseUri + endPointPath + getParams(endpoint, data);
};

const generateErrorMsg = (errors, process) => {
  let responseBody;
  switch (process) {
    case 'login':
      responseBody = {
        message: errors.error
          ? errors.error
          : 'Please start the registration process again.',
      };
      break;
    case 'validate':
      responseBody = {
        message: errors.error ? errors.error : errors.method,
      };
      break;
    case 'confirm':
      responseBody = {
        message: errors.error ? errors.error : errors.validation_pin,
      };
      break;
    case 'registry':
      responseBody = {
        birthday: errors['Account.birthdate'],
        email: errors['Account.email'],
        firstname: errors['Account.first_name'],
        lastname: errors['Account.last_name'],
        password: errors['Account.password'],
        phone: errors['Account.phone'],
      };
      break;
    case 'reset-pass':
      responseBody = {
        message: 'Email not found',
      };
      break;
    case 'update-pass':
      responseBody = {
        messagePin: errors.validation_pin || '',
        messagePassword: errors['Account.password'] || '',
      };
      break;
    default:
      break;
  }
  return responseBody;
};

const getProvidersByService = (service, providers) => {
  const response = [];
  providers.forEach((provider) => {
    // eslint-disable-next-line array-callback-return
    provider.services.map((serviceId) => {
      if (serviceId === service) {
        response.push(provider.id);
      }
    });
  });
  return response;
};

const getMapedServices = (services, providers) => {
  let serviceDesc;
  return services.map((service) => {
    serviceDesc = service.Service;
    return {
      id: serviceDesc.id,
      pms_id: serviceDesc.pms_id,
      name: serviceDesc.description,
      duration: serviceDesc.duration,
      is_applied_for_new_patients: true,
      is_applied_for_existent_patients: true,
      active: serviceDesc.is_enabled,
      providers: getProvidersByService(serviceDesc.id, providers),
      bid: '',
    };
  });
};

const getMapedProviders = (providers) => {
  let providerDesc;
  return providers.map((provider) => {
    providerDesc = provider.Provider;
    return {
      id: providerDesc.id,
      first_name: providerDesc.first_name,
      last_name: providerDesc.last_name,
      speciality: providerDesc.specialty,
      photo: providerDesc.photo,
      pms_id: '', // missing
      services: providerDesc.service_ids,
      rating: 0,
      biography: providerDesc.biography,
      reviews_count: 0,
      reviews_enabled: false,
    };
  });
};

export const mapResponse = (data) => {
  const clinicInfo = data.ClinicInfo;
  const settings = data.Settings;
  let answers = settings.engagementSettingAnswers;
  if (answers.length > 0) {
    answers.unshift({ value: '' });
    answers = Object.values(answers).map((answer) => answer.value);
  } else {
    answers = [];
  }
  const { widgetQuestions } = settings;
  const question = settings.question || false;
  const providers = getMapedProviders(data.providers);
  const services = getMapedServices(data.services, providers);
  const questionEnabled = settings.isQuestionEnabled;

  const formattedPhoneNumber = formatPhoneNumber(clinicInfo.PracticePhone);
  function formatPhoneNumber(phoneNumberString) {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return '';
  }
  const response = {
    clinic: {
      clinic_info: {
        title: clinicInfo.clinic_name ? clinicInfo.clinic_name : 'Clinic Name',
        phone: formattedPhoneNumber,
        email: [
          clinicInfo.clinic_email
            ? clinicInfo.clinic_email
            : 'clinic.email@mail.test',
        ],
        fb_link: '',
        google_link: '',
        twitter_link: '',
        youtube_link: '',
        linkedin_link: '',
        isMultiofficeEnabled: settings.isMultiofficeEnabled,
        googleTagManagerId: settings.googleTagManagerId,
      },
      booking_options: {
        time_frame: {
          min: {
            unit: 'd',
            value: 1,
          },
          max: {
            unit: 'w',
            value: 2,
          },
        },
        clinic_schedule: {
          Friday: [
            {
              start_h: 8,
              start_m: 0,
              end_h: 17,
              end_m: 0,
            },
          ],
          Monday: [
            {
              start_h: 8,
              start_m: 0,
              end_h: 17,
              end_m: 0,
            },
          ],
          Saturday: [
            {
              start_h: 8,
              start_m: 0,
              end_h: 14,
              end_m: 0,
            },
          ],
          Sunday: [
            {
              start_h: 9,
              start_m: 0,
              end_h: 13,
              end_m: 0,
            },
          ],
          Thursday: [
            {
              start_h: 8,
              start_m: 0,
              end_h: 17,
              end_m: 0,
            },
          ],
          Tuesday: [
            {
              start_h: 8,
              start_m: 0,
              end_h: 17,
              end_m: 0,
            },
          ],
          Wednesday: [
            {
              start_h: 8,
              start_m: 0,
              end_h: 17,
              end_m: 0,
            },
          ],
        },
      },
      style_options: {
        logo: settings.logotype ? settings.logotype : '',
        font: `${settings.font}`,
        font_color: settings.fontColor,
        theme_color: settings.buttonColor,
      },
      question_options: {
        enabled: question && questionEnabled,
        question_text: question,
        possible_answers: answers,
      },
      widget_questions: widgetQuestions,
      type: '',
    },
    services,
    providers,
  };

  return response;
};

export const mapServices = (services) => services.map((serviceInfo) => ({
  service: {
    id: serviceInfo.service.id,
    pmsId: serviceInfo.service.pmsId,
    description: serviceInfo.service.description,
    duration: serviceInfo.service.duration || 30,
    scheduleLimit: serviceInfo.service.scheduleLimit,
    hasUpcoming: serviceInfo.hasUpcoming,
  },
}));

export const getRequestValues = (data) => ({
  serviceId: data.serviceId,
  providerId: data.providerId,
  pmsId: data.providerPmsId,
  duration: data.serviceDuration,
  startDate: data.dateFrom !== null && data.dateFrom !== '' ? transformDate(data.dateFrom, 'start') : '',
  endDate: data.dateTo !== null && data.dateTo !== '' ? transformDate(data.dateTo, 'end') : '',
  am: data.preferredMorning,
  noon: data.preferredNoon,
  evening: data.preferredEvening,
  hashBid: data.hashBid,
  patientId: data.patientId,
});

const sortSlots = (a, b) => {
  const aUnix = new Date(a.start).getTime();
  const bUnix = new Date(b.start).getTime();

  if (aUnix > bUnix) {
    return 1;
  }

  if (bUnix > aUnix) {
    return -1;
  }

  return 0;
};

export const sortOpenings = (openings) => openings.sort(sortSlots);

export const setCookies = (token) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 1);
  cookie.save('token', token, {
    path: '/',
    expires,
    // maxAge: 30000,
    // secure: true,
  });
};

export const setBrowserNotSupportCookies = () => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 1);
  cookie.save('browserNotSupported', false, {
    path: '/',
    expires,
  });
};

export const mapPractices = (practices) => practices.map((practice) => ({
  id: practice.id,
  businessId: practice.business_id,
  hbid: practice.hbid,
  name: practice.practice_name,
  address1: practice.practice_address1,
  address2: practice.practice_address2,
  city: practice.practice_city,
  state: practice.practice_state,
  zip: practice.practice_zip,
  phone1: practice.practice_phone1,
  phone2: practice.practice_phone2,
}));

export const getLocationOptions = (practices) => {
  const options = practices;
  return options.map((practice) => ({
    value: practice.hbid,
    label: `${practice.name}
     ${practice.address1} ${practice.address2} ${practice.city} ${practice.state} ${practice.zip}`,
  }));
};

export const getFamilyMembersOptions = (members) => members.map((member) => ({
  value: member.patient.id,
  label: `${member.patient.firstName}`,
}));
