import camelcaseKeys from 'camelcase-keys-deep';
import 'core-js';
import {
  FETCH_CLINIC,
  FETCH_CLINIC_REJECTED,
  FETCH_CLINIC_FULFILLED,
  CLEAR_ERRORS,
  GET_EXTERNAL_TOKEN,
  FETCH_APPT_APPROVAL_REQUEST,
  FETCH_APPT_APPROVAL_FULFILLED,
  APPT_APPROVAL_ERROR,
} from '../constants';
import {
  handleErrors,
  headers,
  uri,
  uriWithParams,
  mapResponse,
} from '../utils/apiUtils';

/* eslint-disable */
export function fetchClinic(clinicId) {
  /* eslint-enable */
  return function (dispatch) {
    dispatch({ type: FETCH_CLINIC });
    const searchParams = new URLSearchParams(window.location.search);
    const campaignId = searchParams.get('campaign_id');
    fetch(uri('settings') + (campaignId ? `?campaign_id=${campaignId}` : ''), {
      method: 'GET',
      headers,
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => mapResponse(json.data.item))
      .then((clinicInfo) => {
        dispatch({
          type: FETCH_CLINIC_FULFILLED,
          payload: {
            clinicInfo: camelcaseKeys(clinicInfo, { deep: true }),
            clinicId,
          },
        });
      })
      .catch((error) => dispatch({ type: FETCH_CLINIC_REJECTED, payload: error }));
  };
}

export function clearErrors() {
  return function (dispatch) {
    dispatch({ type: CLEAR_ERRORS, payload: {} });
  };
}

export function getExternalToken() {
  /* eslint-enable */
  return function (dispatch) {
    const urlParams = new URLSearchParams(window.location.search);
    dispatch({
      type: GET_EXTERNAL_TOKEN,
      payload: urlParams.get('token'),
    });
  };
}

// Finalizr aqui

/* eslint-disable */
export function approveRequest(token) {
  /* eslint-enable */
  return function (dispatch) {
    dispatch({ type: FETCH_APPT_APPROVAL_REQUEST });
    fetch(uriWithParams('approval', token), {
      method: 'POST',
      headers,
    }).then((response) => {
      if (response.status === 200 || response.status === 400) {
        dispatch({
          type: FETCH_APPT_APPROVAL_FULFILLED,
          payload: response.status !== 400,
        });
      } else {
        dispatch({ type: APPT_APPROVAL_ERROR, payload: true });
      }
    });
  };
}
