import cookie from 'react-cookies';

function getPosition(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

function compareClinicId(newClinicId, oldClinicId) {
  if (newClinicId !== oldClinicId) {
    localStorage.clear();
    sessionStorage.clear();
    cookie.remove('token', { path: '/' });
    return newClinicId;
  }
  return oldClinicId;
}

function getClinicId() {
  const baseClinicId = window.location.pathname;
  const newClinicId = baseClinicId
    .slice(0, getPosition(baseClinicId, '/', 2))
    .slice(1);
  if (newClinicId === 'health') {
    return newClinicId;
  }
  if (newClinicId === '/' || newClinicId < 1) {
    document.body.innerHTML = `
    <div style="padding: 20px; text-align: center;">
      <h1>Clinic ID Required</h1>
      <p>A valid clinic ID is required to access this page.</p>
      <p>Please contact your service provider for more details.</p>
    </div>
  `;
  }
  const clinicId = compareClinicId(
    newClinicId,
    localStorage.getItem('clinicId'),
  );
  localStorage.setItem('clinicId', clinicId);
  window.__BASENAME__ = clinicId;
  return clinicId;
}

export default getClinicId;
