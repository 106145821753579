import {
  getSessionInt,
  getSessionStringNull,
  getSessionJsonWithDefault,
  getSessionIntWithDefault,
} from '../../utils/storageGetUtils';

export default {
  selectedService: getSessionJsonWithDefault('selectedService', {}),
  selectedServiceId: getSessionInt('selectedServiceId'),
  selectedServiceProviders: getSessionJsonWithDefault(
    'selectedServiceProviders',
    [],
  ),
  selectedProviderId: getSessionInt('selectedProviderId'),
  selectedProviderForReview: getSessionJsonWithDefault(
    'selectedProviderForReview',
    {},
  ),
  selectedProviderReviews: getSessionJsonWithDefault(
    'selectedProviderReviews',
    [],
  ),
  activePageReview: getSessionInt('activePageReview'),
  reviewFetching: false,
  reviewFetched: false,
  fetchingTime: false,
  fetchedTime: false,
  request: {
    patientId: getSessionIntWithDefault('selectedPatientId', 0),
    patientPmsId: getSessionInt('selectedPatientPmsId'),
    serviceId: getSessionInt('selectedServiceId'),
    providerId: getSessionInt('selectedProviderId'),
    providerPmsId: getSessionInt('selectedProviderPmsId'),
    dateFrom: getSessionStringNull('dateFrom'),
    dateTo: getSessionStringNull('dateTo'),
    preferredMorning: false,
    preferredNoon: false,
    preferredEvening: false,
    serviceDuration: getSessionInt('serviceDuration'),
    hashBid: null,
    activePatient: true,
  },
  openings: getSessionJsonWithDefault('openings', []),
  count: 3,
  selectedTime: getSessionStringNull('selectedTime'),
  fetchingSend: false,
  fetchedSend: false,
  comment: getSessionStringNull('comment'),
  appointmentCreatedID: getSessionInt('appointmentCreatedID'),
  patientEmail: getSessionStringNull('patientEmail'),
  createdFor: getSessionStringNull('createdFor'),
  errors: {},
  appReqErrMsgModal: false,
  skipProvider: false,
};
