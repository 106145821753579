import {
  SERVICES_AVAILABLE_SUCCESS,
  SERVICES_AVAILABLE_REQUEST,
  SELECTED_SERVICE,
  RESET_APPT_REQUEST,
} from '../constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case SERVICES_AVAILABLE_REQUEST: {
      return {
        ...state,
        fetchingServices: true,
      };
    }

    case SERVICES_AVAILABLE_SUCCESS: {
      return {
        ...state,
        fetchingServices: false,
        services: action.payload,
      };
    }

    case SELECTED_SERVICE: {
      return {
        ...state,
        selectedServiceId: parseInt(action.payload.service.id),
        request: {
          ...state.request,
          serviceId: parseInt(action.payload.service.id),
        },
      };
    }

    case RESET_APPT_REQUEST: {
      return {
        ...state,
        selectedServiceId: null,
        request: {
          ...state.request,
          serviceId: null,
        },
      };
    }

    default:
      return state;
  }
};
