import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import UserInfoFields from '../forms/UserInfoFields';
import {
  validateFormData,
  formatPhoneNumber,
  sanitizeNames,
  sanitizePhone,
} from '../forms/utils';
import { clearErrors } from '../../actions/homeActions';
import { Forms } from '../../elements/profile/ProfileUser';
import { setPatientRequest, editUserFamilyMember, editInfoUser } from '../../actions/profileActions';

const AddButton = styled(Button)`
  padding: 8px 16px;
  color: #fff;
  text-decoration: none;
  border-radius: 25px;
  background-color: ${(props) => props.themeColor || '#258ADA'};
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
`;

const ResetButton = styled(Button)`
  padding: 8px 16px;
  color: ${(props) => props.themeColor || '#258ADA'};
  text-decoration: none;
  border-radius: 25px;
  box-shadow: inset 0px 0px 0px 1px ${(props) => props.themeColor || '#258ADA'};
  background-color: transparent;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  box-sizing: border-box;
`;

export function ProfileEdit(props) {
  const {
    accountInfo, patientId, familyMembers, token, hashBid,
  } = props;
  const [state, setState] = useState({
    patientId,
    firstName: accountInfo?.firstName || '',
    lastName: accountInfo?.lastName || '',
    birthday: moment(accountInfo?.birthdate, 'YYYY-MM-DD') || moment(),
    phone: accountInfo?.phone || '',
    email: accountInfo?.email || '',
    hashBid,
    formMsgErrors: {},
    familyMembers,
    selectedLocation: '',
    selectedPatient: patientId,
  });
  const dispatch = useDispatch();

  const handlePatientChange = useCallback((patientSelect) => {
    if (patientSelect) {
      const patient = familyMembers.find((x) => x.patient.id === patientSelect);
      setState((prevState) => ({
        ...prevState,
        firstName: patient?.patient?.firstName || '',
        lastName: patient?.patient?.lastName || '',
        phone: patient?.patient?.phone || '',
        email: patient?.patient?.email || '',
        formMsgErrors: {},
        birthday: moment(patient?.patient?.birthdate, 'YYYY-MM-DD'),
      }));
    }
    dispatch(setPatientRequest(patientSelect));
  }, [familyMembers, dispatch]);

  useEffect(() => {
    if (patientId !== state.selectedPatient) {
      setState({
        patientId,
        firstName: accountInfo?.firstName || '',
        lastName: accountInfo?.lastName || '',
        birthday: moment(accountInfo?.birthdate, 'YYYY-MM-DD'),
        phone: accountInfo?.phone || '',
        email: accountInfo?.email || '',
        formMsgErrors: {},
        familyMembers,
        selectedLocation: '',
        selectedPatient: patientId,
        hashBid,
      });
      handlePatientChange(patientId);
    }
  }, [
    patientId,
    familyMembers,
    accountInfo?.firstName,
    accountInfo?.lastName,
    accountInfo?.birthdate,
    accountInfo?.phone,
    accountInfo?.email,
    state.selectedPatient,
    hashBid,
    handlePatientChange,
  ]);

  const onChange = (value, name) => {
    let inputValue = value;
    if (name === 'firstName' || name === 'lastName') {
      inputValue = sanitizeNames(value);
    }
    if (name === 'phone') {
      inputValue = sanitizePhone(value);
    }
    if (name === 'phone' && value.length > 9 && sanitizePhone(value)) {
      inputValue = formatPhoneNumber(sanitizePhone(value));
    }
    setState((prevState) => ({
      ...prevState,
      [name]: inputValue,
      formMsgErrors: {
        ...prevState.formMsgErrors,
        [name]: '',
      },
    }));
  };

  const displayFormErrors = (formElements, errors) => {
    Object.keys(formElements).forEach((key) => {
      setState((prevState) => ({
        formMsgErrors: {
          ...prevState.formMsgErrors,
          [key]: (errors[key]) || '',
        },
      }));
    });
  };

  const handleDayChange = (birthday) => {
    setState((prevState) => ({
      ...prevState,
      formMsgErrors: {
        ...prevState.formMsgErrors,
        birthday: '',
      },
      birthday: moment(birthday, 'MM-DD-YYYY', true),
    }));
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const MainPatientId = getMainAccountPatientId(familyMembers);
    if (state.phone !== '') {
      setState({ phone: formatPhoneNumber(state.phone) });
    }
    const formData = {
      firstName: state.firstName,
      lastName: state.lastName,
      birthday: state.birthday,
      phone: state.phone,
      email: state.email,
    };
    const type = (patientId !== MainPatientId.patientId && patientId > 0) ? '' : 'account';
    if (!Object.entries(validateForm(formData, type)).length > 0) {
      formData.patientId = patientId;
      formData.birthday = moment(state.birthday, 'MM-DD-YYYY').format('YYYY-MM-DD');
      if (type !== 'account') {
        dispatch(editUserFamilyMember(formData, token, patientId, MainPatientId));
      } else {
        dispatch(editInfoUser(formData, token));
      }
    }
  };

  const getMainAccountPatientId = () => {
    const patient = familyMembers.find((x) => x.patient.isMaster === true);
    return {
      patientId: patient.patient.id,
      patientPmsId: patient.patient.pmsId,
    };
  };

  const validateForm = (formData, type) => {
    const formErrors = validateFormData(formData, type);
    displayFormErrors(formData, formErrors);
    return formErrors;
  };

  return (
    <>
      <Forms onSubmit={onSubmit}>
        <div className="my-3">
          <UserInfoFields
            column
            data={state}
            onChange={onChange}
            fontColor={props.fontColor}
            themeColor={props.themeColor}
            errors={props.errors.length ? props.errors : state.formMsgErrors}
            handleDayChange={handleDayChange}
          />
        </div>
      </Forms>
      <Row className="align-items-center gap-4">
        <Col xs={12} md={1}>
          {' '}
          <AddButton themeColor={props.themeColor} type="submit" onClick={onSubmit}>
            Save
          </AddButton>
        </Col>
        <Col xs={12} md={2}>
          <ResetButton
            themeColor={props.themeColor}
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                patientId,
                firstName: state?.firstName || '',
                lastName: state?.lastName || '',
                birthday: moment(state?.birthday, 'YYYY-MM-DD') || moment(),
                phone: state?.phone || '',
                email: state?.email || '',
              }));
              dispatch(clearErrors());
            }}
          >
            Cancel
          </ResetButton>
        </Col>
      </Row>
    </>
  );
}

ProfileEdit.propTypes = {
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  familyMembers: PropTypes.array.isRequired,
  accountInfo: PropTypes.object.isRequired,
  patientId: PropTypes.number.isRequired,
  hashBid: PropTypes.string.isRequired,
};

ProfileEdit.defaultProps = {
  familyMembers: [],
  practicesList: [],
  practicesOptions: [],
  hashBid: 0,
};

export default connect()(ProfileEdit);
