import styled from 'styled-components';
import { PageContainer } from '../components/styles';
// eslint-disable-next-line
export const Root = styled(PageContainer)`
  padding-bottom: 45px;

  @media (max-width: 767px) {
    padding-top: 60px;
    min-height: auto;
  }
`;

Root.displayName = 'Root';

export const Title = styled.h4`
  color: ${(props) => props.fontColor || '#242424'};
`;

Title.displayName = 'Title';

export const ButtonS = styled.div`
  margin-top: 45px;
`;

ButtonS.displayName = 'ButtonS';

export const ButtonSModalDiv = styled.div`
  margin-top: 20px;
`;

ButtonSModalDiv.displayName = 'ButtonSModalDiv';

export const AppointmentTitle = styled.h4`
  font-size: 1.3em;
  font-family: Gotham-Book;
  color: ${(props) => props.fontColor || 'rgba(0, 0, 0, 1)'};
  opacity: 0.8;
`;

export const TitleDescription = styled(AppointmentTitle)`
  margin: 60px 0 0;

  @media (max-width: 1199px) {
    margin: 30px 0 0;
  }
  @media (max-width: 991px) {
    margin: 15px 0 0;
  }

  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const AppointmentContent = styled.h4`
  font-size: 1.3em;
  font-family: Gotham-Book;
  color: ${(props) => props.fontColor || 'rgba(0, 0, 0, 1)'};
  opacity: 0.4;

  @media (max-width: 767px) {
    margin: 0;
  }
`;

export const AppointmentDescription = styled.div`
  margin-bottom: 50px;

  @media (max-width: 767px) {
    margin-bottom: 0;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    ${AppointmentContent} {
      margin: 40px 0 0;
    }
  }
`;
