import React from 'react';
import styled from 'styled-components';

const IconS = styled.svg`
  fill: rgb(255, 255, 255);
`;
/* eslint-disable */
export const addAvatarIcon = () => (
  <IconS
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    //    viewBox="0 0 18 14"
    viewBox="0 0 32 32"
  >
    <g>
      <g>
        <path
          d="M28,6h-4l-4-4h-8.001L8,6H4c0,0-4,0-4,4v12c0,4,4,4,4,4s5.662,0,11.518,0
			c1.614,2.411,4.361,3.999,7.482,4c3.875-0.002,7.167-2.454,8.436-5.889C31.995,23.076,32,22,32,22s0-8,0-12S28,6,28,6z
			 M14.033,21.66C11.686,20.848,10,18.626,10,16c0-3.312,2.684-6,6-6c1.914,0,3.607,0.908,4.706,2.306
			C16.848,13.321,14,16.822,14,21C14,21.223,14.018,21.441,14.033,21.66z M23,27.883c-3.801-0.009-6.876-3.084-6.885-6.883
			c0.009-3.801,3.084-6.876,6.885-6.885c3.799,0.009,6.874,3.084,6.883,6.885C29.874,24.799,26.799,27.874,23,27.883z"
        />
        <polygon
          points="24.002,16 22,16 22,20 18,20 18,22 22,22 22,26 24.002,26 24.002,22 28,22 28,20
			24.002,20 		"
        />
      </g>
    </g>
  </IconS>
);

export const avatarIcon = (themeColor) => (
  <IconS
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    //    viewBox="0 0 18 14"
    viewBox="0 0 299.997 299.997"
  >
    <g>
      <g>
        <path
          fill={themeColor}
          d="M149.996,0C67.157,0,0.001,67.158,0.001,149.997c0,82.837,67.156,150,149.995,150s150-67.163,150-150
			C299.996,67.156,232.835,0,149.996,0z M150.453,220.763v-0.002h-0.916H85.465c0-46.856,41.152-46.845,50.284-59.097l1.045-5.587
			c-12.83-6.502-21.887-22.178-21.887-40.512c0-24.154,15.712-43.738,35.089-43.738c19.377,0,35.089,19.584,35.089,43.738
			c0,18.178-8.896,33.756-21.555,40.361l1.19,6.349c10.019,11.658,49.802,12.418,49.802,58.488H150.453z"
        />
      </g>
    </g>
  </IconS>
);

export const pencilIcon = (themeColor, size) => (
  <IconS
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 528.899 528.899"
  >
    <path
      fill={themeColor}
      d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
      c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
      C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
      L27.473,390.597L0.3,512.69z"
    />
  </IconS>
);

export const exitIcon = () => (
  <IconS
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20"
    viewBox="0 0 24 20"
  >
    <path d="M3 14C2.73333 14 2.5 13.9 2.3 13.7C2.1 13.5 2 13.2667 2 13V3C2 2.73333 2.1 2.5 2.3 2.3C2.5 2.1 2.73333 2 3 2H7.85V3H3V13H7.85V14H3ZM11.1 10.9167L10.3833 10.2L12.0833 8.5H6.25V7.5H12.05L10.35 5.8L11.0667 5.08333L14 8.01667L11.1 10.9167Z" fill="white" />
  </IconS>
);

export const phoneIcon = (themeColor) => (
  <IconS
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20"
    viewBox="0 0 24 20"
  >
    <path d="M16.6667 12.9167C15.6667 12.9167 14.5833 12.75 13.6667 12.4167C13.5833 12.4167 13.5 12.4167 13.4167 12.4167C13.1667 12.4167 13 12.5 12.8333 12.6667L11 14.5C8.66667 13.25 6.66667 11.3333 5.5 9L7.33333 7.16667C7.58333 6.91667 7.66667 6.58333 7.5 6.33333C7.25 5.41667 7.08333 4.33333 7.08333 3.33333C7.08333 2.91667 6.66667 2.5 6.25 2.5H3.33333C2.91667 2.5 2.5 2.91667 2.5 3.33333C2.5 11.1667 8.83333 17.5 16.6667 17.5C17.0833 17.5 17.5 17.0833 17.5 16.6667V13.75C17.5 13.3333 17.0833 12.9167 16.6667 12.9167ZM4.16667 4.16667H5.41667C5.5 4.91667 5.66667 5.66667 5.83333 6.33333L4.83333 7.33333C4.5 6.33333 4.25 5.25 4.16667 4.16667ZM15.8333 15.8333C14.75 15.75 13.6667 15.5 12.6667 15.1667L13.6667 14.1667C14.3333 14.3333 15.0833 14.5 15.8333 14.5V15.8333Z" fill={themeColor} />
    
  </IconS>
);
export const dateIcon = (themeColor) => (
  <IconS
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20"
    viewBox="0 0 24 20"
  >
    <path fill={themeColor} d="M10 5.90909C10.8633 5.90909 11.5556 5.17273 11.5556 4.27273C11.5556 3.96182 11.4778 3.67545 11.33 3.43L10 1L8.67 3.43C8.52222 3.67545 8.44444 3.96182 8.44444 4.27273C8.44444 5.17273 9.14444 5.90909 10 5.90909ZM14.6667 8.36364H10.7778V6.72727H9.22222V8.36364H5.33333C4.04222 8.36364 3 9.46 3 10.8182V18.1818C3 18.6318 3.35 19 3.77778 19H16.2222C16.65 19 17 18.6318 17 18.1818V10.8182C17 9.46 15.9578 8.36364 14.6667 8.36364ZM15.4444 17.3636H4.55556V14.9091C5.25556 14.9009 5.92444 14.6064 6.42222 14.0827L7.27 13.2073L8.10222 14.0827C9.12111 15.1545 10.8944 15.1464 11.9056 14.0827L12.7456 13.2073L13.5778 14.0827C14.0756 14.6064 14.7444 14.9009 15.4444 14.9091V17.3636ZM15.4444 13.6818C15.0478 13.6736 14.6744 13.5182 14.3944 13.2155L12.7378 11.4727L11.0733 13.2155C10.4978 13.8209 9.49444 13.8209 8.91889 13.2155L7.26222 11.4727L5.59778 13.2155C5.32556 13.51 4.95222 13.6736 4.55556 13.6818V10.8182C4.55556 10.3682 4.90556 10 5.33333 10H14.6667C15.0944 10 15.4444 10.3682 15.4444 10.8182V13.6818Z" />
  </IconS>
);
export const mailIcon = (themeColor) => (
  <IconS
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20"
    viewBox="0 0 24 20"
  >
    <path d="M18.3327 4.9987C18.3327 4.08203 17.5827 3.33203 16.666 3.33203H3.33268C2.41602 3.33203 1.66602 4.08203 1.66602 4.9987V14.9987C1.66602 15.9154 2.41602 16.6654 3.33268 16.6654H16.666C17.5827 16.6654 18.3327 15.9154 18.3327 14.9987V4.9987ZM16.666 4.9987L9.99935 9.16536L3.33268 4.9987H16.666ZM16.666 14.9987H3.33268V6.66536L9.99935 10.832L16.666 6.66536V14.9987Z" fill={themeColor} />
  </IconS>
);
export const calendarIcon = (themeColor) => (
  <IconS
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20"
    viewBox="0 0 24 20"
  >
    <path d="M7.2001 15.9016C7.2001 16.3986 7.60304 16.8016 8.1001 16.8016C8.59715 16.8016 9.0001 16.3986 9.0001 15.9016V14.1016C9.0001 13.6045 8.59715 13.2016 8.1001 13.2016C7.60304 13.2016 7.2001 13.6045 7.2001 14.1016V15.9016Z" fill={themeColor ? themeColor : "#258ADA"} />
    <path d="M12.0001 16.8016C11.503 16.8016 11.1001 16.3986 11.1001 15.9016V14.1016C11.1001 13.6045 11.503 13.2016 12.0001 13.2016C12.4972 13.2016 12.9001 13.6045 12.9001 14.1016V15.9016C12.9001 16.3986 12.4972 16.8016 12.0001 16.8016Z" fill={themeColor ? themeColor : "#258ADA"} />
    <path d="M15.0001 15.9016C15.0001 16.3986 15.403 16.8016 15.9001 16.8016C16.3972 16.8016 16.8001 16.3986 16.8001 15.9016V14.1016C16.8001 13.6045 16.3972 13.2016 15.9001 13.2016C15.403 13.2016 15.0001 13.6045 15.0001 14.1016V15.9016Z" fill={themeColor ? themeColor : "#258ADA"} />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.0001 4.50156C6.0001 4.00451 6.40304 3.60156 6.9001 3.60156C7.39715 3.60156 7.8001 4.00451 7.8001 4.50156V6.00156H16.2001V4.50156C16.2001 4.00451 16.603 3.60156 17.1001 3.60156C17.5972 3.60156 18.0001 4.00451 18.0001 4.50156V6.00156H18.6001C19.5942 6.00156 20.4001 6.80745 20.4001 7.80156V18.6016C20.4001 19.5957 19.5942 20.4016 18.6001 20.4016H5.4001C4.40599 20.4016 3.6001 19.5957 3.6001 18.6016V7.80156C3.6001 6.80745 4.40599 6.00156 5.4001 6.00156H6.0001V4.50156ZM18.6001 7.80156H5.4001V10.2016H18.6001V7.80156ZM5.4001 18.6016L5.4001 12.0016H18.6001V18.6016H5.4001Z" fill={themeColor ? themeColor : "#258ADA"} />
  </IconS>
);
export const deleteIcon = (themeColor) => (
  <IconS
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20"
    viewBox="0 0 24 20"
  >
    <path d="M5.625 18C5.14375 18 4.73192 17.8261 4.3895 17.4782C4.0465 17.1298 3.875 16.7111 3.875 16.2222V4.66667H3V2.88889H7.375V2H12.625V2.88889H17V4.66667H16.125V16.2222C16.125 16.7111 15.9538 17.1298 15.6114 17.4782C15.2684 17.8261 14.8562 18 14.375 18H5.625ZM14.375 4.66667H5.625V16.2222H14.375V4.66667ZM7.375 14.4444H9.125V6.44444H7.375V14.4444ZM10.875 14.4444H12.625V6.44444H10.875V14.4444Z" fill={themeColor ? themeColor : "#424242"} />
  </IconS>
);
