import {
  FETCH_UPDATE_PASSWORD,
  FETCH_UPDATE_PASSWORD_FULFILLED,
  FETCH_UPDATE_PASSWORD_REJECTED,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_CONFIRM_FAILURE,
  RESET_PASSWORD_MODAL_SHOW,
  RESET_PASSWORD_MODAL_CLOSE,
  RESET_PASSWORD_MODAL_HIDE,
} from '../constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case FETCH_UPDATE_PASSWORD: {
      return {
        ...state,
        fetching: true,
        fetched: false,
      };
    }
    case FETCH_UPDATE_PASSWORD_FULFILLED: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        result: action.payload.data,
        message: action.payload.message,
        errors: {},
      };
    }
    case FETCH_UPDATE_PASSWORD_REJECTED: {
      return {
        ...state,
        fetching: false,
        fetched: false,
        errors: action.payload.data.errors,
      };
    }

    case RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        errors: {
          ...state,
          email: action.payload.data.errors.message,
        },
      };
    }

    case RESET_PASSWORD_CONFIRM_FAILURE: {
      return {
        ...state,
        errors: {
          ...state,
          pin: action.payload.data.errors.messagePin,
          password: action.payload.data.errors.messagePassword,
        },
      };
    }

    case RESET_PASSWORD_MODAL_SHOW: {
      return {
        ...state,
        resetPassModal: true,
      };
    }

    case RESET_PASSWORD_MODAL_CLOSE: {
      return {
        ...state,
        resetPassModal: false,
      };
    }

    case RESET_PASSWORD_MODAL_HIDE: {
      return {
        ...state,
        resetPassModal: false,
        resetEmail: action.payload.data.email,
      };
    }

    default:
      return state;
  }
};
