import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import ProfileMember from './ProfileMember';

const Title = styled.h3`
  text-align: center;
  font-size: 1.4em;
  margin: 30px;
  color: ${(props) => props.colorSuccess || '#424242'};
`;

function ProfileFamilyMembers({
  members,
  colorSuccess,
  token,
  editSuccess,
  history,
}) {
  if (members.length > 0) {
    return (
      <Col xs={12}>
        <hr />
        <Title colorSuccess={colorSuccess}>Family Members:</Title>
        <hr />
        <div className="my-4">
          <table className="table table-success table-striped">
            {members.map((member, index) => {
              const i = index;
              if (!member.patient.isMaster) {
                return (
                  <ProfileMember
                    key={i}
                    patientId={member.patient.id}
                    patientPmsId={member.patient.pmsId}
                    firstName={member.patient.firstName}
                    lastName={member.patient.lastName}
                    birthday={member.patient.birthdate}
                    phone={member.patient.phone}
                    email={member.patient.email}
                    photo={member.patient.photo || ''}
                    token={token}
                    relationShip={member.patient.relationshipType || 0}
                    editSuccess={editSuccess}
                    history={history}
                  />
                );
              }
              return null;
            })}
          </table>
        </div>

      </Col>
    );
  }
  return null;
}

ProfileFamilyMembers.propTypes = {
  members: PropTypes.array.isRequired,
  colorSuccess: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  editSuccess: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default ProfileFamilyMembers;
