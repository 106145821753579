import styled from 'styled-components';

export const RadioLabel = styled.label`
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: start;
  gap: 10px;
  color: ${(props) => props.fontColor || '#424242'};
  opacity: 0.8;
  word-break: break-word;

  > svg {
    fill: ${(props) => props.fontColor || 'rgb(0, 0, 0)'};
  }

  &:focus,
  &:hover {
    opacity: 1;
    outline: none;
    cursor: pointer;
    color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
    > svg {
      opacity: 1;
      fill: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
    }
  }

  &.select {
    opacity: 1;
    color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
    > svg {
      opacity: 1;
      fill: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
    }
  }

  @media (max-width: 767px) {
    font-size: 0.9em;
    margin-bottom: 20px;
    min-height: auto;
    align-items: center;
  }
`;

export const RadioInput = styled.input`
  width: 20px !important;
  height: 20px !important;
`;
