import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 389px;
  height: ${(props) => (props.height ? props.height : 'auto')};
`;

const TextLoading = styled.h1`
  font-size: 2em;
  margin: 0;
  color: ${(props) => (props.color ? props.color : 'grey')};
`;

const OuterTextLoading = styled.h1`
  /* font-size: 3em; */
  margin: 0;
  color: ${(props) => (props.color ? props.color : 'grey')};
`;

function Loading(
  {
    themeColor, innerText, height, outerText,
  },
) {
  return (
    <ContainerLoading height={height}>
      <TextLoading color={themeColor}>{innerText}</TextLoading>
      <OuterTextLoading color={themeColor}>{outerText}</OuterTextLoading>
    </ContainerLoading>
  );
}

Loading.propTypes = {
  themeColor: PropTypes.string.isRequired,
  innerText: PropTypes.string,
  height: PropTypes.string,
  outerText: PropTypes.string,
};

Loading.defaultProps = {
  height: 'auto',
  innerText: 'loading...',
  outerText: '',
};

export default Loading;
