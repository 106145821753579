export const FETCH_CLINIC = 'FETCH_CLINIC';
export const FETCH_CLINIC_REJECTED = 'FETCH_CLINIC_REJECTED';
export const FETCH_CLINIC_FULFILLED = 'FETCH_CLINIC_FULFILLED';

export const SELECT_SERVICE = 'SELECT_SERVICE';
export const SELECT_PROVIDER = 'SELECT_PROVIDER';
export const SKIP_PROVIDER = 'SKIP_PROVIDER';
export const SELECT_DATE_FROM = 'SELECT_DATE_FROM';
export const SELECT_DATE_TO = 'SELECT_DATE_TO';
export const SELECT_MORNING = 'SELECT_MORNING';
export const SELECT_NOON = 'SELECT_NOON';
export const SELECT_EVENING = 'SELECT_EVENING';

export const FETCH_OPENING_TIME = 'FETCH_OPENING_TIME';
export const FETCH_OPENING_TIME_REJECTED = 'FETCH_OPENING_TIME_REJECTED';
export const FETCH_OPENING_TIME_FULFILLED = 'FETCH_OPENING_TIME_FULFILLED';

export const USER_AUTH_REQUEST = 'USER_AUTH_REQUEST';
export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS';
export const USER_AUTH_FAILURE = 'USER_AUTH_FAILURE';
export const USER_AUTH_KEY = 'USER_AUTH_KEY';
export const USER_REGISTRY_REQUEST = 'USER_REGISTRY_REQUEST';
export const USER_REGISTRY_SUCCESS = 'USER_REGISTRY_SUCCESS';
export const USER_REGISTRY_FAILURE = 'USER_REGISTRY_FAILURE';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE';

export const SET_DAYS_VIEW = 'SET_DAYS_VIEW';
export const SELECT_TIME = 'SELECT_TIME';

export const FETCH_SEND_APPOINTMENT = 'FETCH_SEND_APPOINTMENT';
export const FETCH_SEND_APPOINTMENT_FULFILLED = 'FETCH_SEND_APPOINTMENT_FULFILLED';
export const FETCH_SEND_APPOINTMENT_REJECTED = 'FETCH_SEND_APPOINTMENT_REJECTED';

export const PROVIDER_REVIEWS_REQUEST = 'PROVIDER_REVIEWS_REQUEST';
export const PROVIDER_REVIEWS_FULFILLED = 'PROVIDER_REVIEWS_FULFILLED';
export const PROVIDER_REVIEWS_REJECTED = 'PROVIDER_REVIEWS_REJECTED';

export const ACTIVE_PAGE_REVIEW = 'ACTIVE_PAGE_REVIEW';

export const CHANGE_AVATAR_USER_REQUEST = 'CHANGE_AVATAR_USER_REQUEST';
export const CHANGE_AVATAR_USER_FULFILLED = 'CHANGE_AVATAR_USER_FULFILLED';
export const CHANGE_AVATAR_USER_REJECTED = 'CHANGE_AVATAR_USER_REJECTED';

export const EDIT_INFO_USER_REQUEST = 'EDIT_INFO_USER_REQUEST';
export const EDIT_INFO_USER_FULFILLED = 'EDIT_INFO_USER_FULFILLED';
export const EDIT_INFO_USER_REJECTED = 'EDIT_INFO_USER_REJECTED';

export const ADD_USER_FAMILY_MEMBER_REQUEST = 'ADD_USER_FAMILY_MEMBER_REQUEST';
export const ADD_USER_FAMILY_MEMBER_FULFILLED = 'ADD_USER_FAMILY_MEMBER_FULFILLED';
export const ADD_USER_FAMILY_MEMBER_REJECTED = 'ADD_USER_FAMILY_MEMBER_REJECTED';

export const EDIT_USER_FAMILY_MEMBER_REQUEST = 'EDIT_USER_FAMILY_MEMBER_REQUEST';
export const EDIT_USER_FAMILY_MEMBER_FULFILLED = 'EDIT_USER_FAMILY_MEMBER_FULFILLED';
export const EDIT_USER_FAMILY_MEMBER_REJECTED = 'EDIT_USER_FAMILY_MEMBER_REJECTED';

export const DELETE_USER_FAMILY_MEMBER_REQUEST = 'DELETE_USER_FAMILY_MEMBER_REQUEST';
export const DELETE_USER_FAMILY_MEMBER_FULFILLED = 'DELETE_USER_FAMILY_MEMBER_FULFILLED';
export const DELETE_USER_FAMILY_MEMBER_REJECTED = 'DELETE_USER_FAMILY_MEMBER_REJECTED';

export const FETCH_USER_FAMILY_MEMBER_REQUEST = 'FETCH_USER_FAMILY_MEMBER_REQUEST';
export const FETCH_USER_FAMILY_MEMBER_FULFILLED = 'FETCH_USER_FAMILY_MEMBER_FULFILLED';
export const FETCH_USER_FAMILY_MEMBER_REJECTED = 'FETCH_USER_FAMILY_MEMBER_REJECTED';

export const FETCH_UPDATE_PASSWORD = 'FETCH_UPDATE_PASSWORD';
export const FETCH_UPDATE_PASSWORD_FULFILLED = 'FETCH_UPDATE_PASSWORD_FULFILLED';
export const FETCH_UPDATE_PASSWORD_REJECTED = 'FETCH_UPDATE_PASSWORD_REJECTED';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const CHECK_USER_STATUS_REQUEST = 'CHECK_USER_STATUS_REQUEST';
export const CHECK_USER_STATUS_SUCCESS = 'CHECK_USER_STATUS_SUCCESS';
export const CHECK_USER_STATUS_FAILURE = 'CHECK_USER_STATUS_FAILURE';

export const VALIDATE_METHOD_REQUEST = 'VALIDATE_METHOD_REQUEST';
export const VALIDATE_METHOD_SUCCESS = 'VALIDATE_METHOD_SUCCESS';
export const VALIDATE_METHOD_FAILURE = 'VALIDATE_METHOD_FAILURE';

export const USER_REGISTRY_VALIDATE_PROCESS_START = 'USER_REGISTRY_VALIDATE_PROCESS_START';
export const USER_REGISTRY_VALIDATE_PROCESS_END = 'USER_REGISTRY_VALIDATE_PROCESS_END';

export const VALIDATE_MODAL_SHOW = 'VALIDATE_MODAL_SHOW';
export const VALIDATE_MODAL_HIDE = 'VALIDATE_MODAL_HIDE';
export const VALIDATE_MODAL_CLOSE = 'VALIDATE_MODAL_CLOSE';

export const CONFIRM_MODAL_SHOW = 'CONFIRM_MODAL_SHOW';
export const CONFIRM_MODAL_HIDE = 'CONFIRM_MODAL_HIDE';
export const CONFIRM_MODAL_CLOSE = 'CONFIRM_MODAL_CLOSE';

export const CONFIRM_METHOD_REQUEST = 'CONFIRM_METHOD_REQUEST';
export const CONFIRM_METHOD_SUCCESS = 'CONFIRM_METHOD_SUCCESS';
export const CONFIRM_METHOD_FAILURE = 'CONFIRM_METHOD_FAILURE';

export const REGISTRY_COMPLETED_MESSAGE_SHOW = 'REGISTRY_COMPLETED_MESSAGE_SHOW';
export const REGISTRY_COMPLETED_MESSAGE_HIDE = 'REGISTRY_COMPLETED_MESSAGE_HIDE';

export const WRONG_PIN_MESSAGE_SHOW = 'WRONG_PIN_MESSAGE_SHOW';
export const WRONG_PIN_MESSAGE_HIDE = 'WRONG_PIN_MESSAGE_HIDE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const RESET_PASSWORD_MODAL_SHOW = 'RESET_PASSWORD_MODAL_SHOW';
export const RESET_PASSWORD_MODAL_HIDE = 'RESET_PASSWORD_MODAL_HIDE';
export const RESET_PASSWORD_MODAL_CLOSE = 'RESET_PASSWORD_MODAL_CLOSE';

export const RESET_PASSWORD_CONFIRM_REQUEST = 'RESET_PASSWORD_CONFIRM_REQUEST';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';
export const RESET_PASSWORD_CONFIRM_FAILURE = 'RESET_PASSWORD_CONFIRM_FAILURE';

export const RESET_PASSWORD_CONFIRM_MODAL_SHOW = 'RESET_PASSWORD_CONFIRM_MODAL_SHOW';
export const RESET_PASSWORD_CONFIRM_MODAL_HIDE = 'RESET_PASSWORD_CONFIRM_MODAL_HIDE';
export const RESET_PASSWORD_CONFIRM_MODAL_CLOSE = 'RESET_PASSWORD_CONFIRM_MODAL_CLOSE';

export const PASSWORD_SUCCESS_MODAL_SHOW = 'PASSWORD_SUCCESS_MODAL_SHOW';
export const PASSWORD_SUCCESS_MODAL_HIDE = 'PASSWORD_SUCCESS_MODAL_HIDE';

export const SERVICES_AVAILABLE_REQUEST = 'SERVICES_AVAILABLE_REQUEST';
export const SERVICES_AVAILABLE_SUCCESS = 'SERVICES_AVAILABLE_SUCCESS';
export const SERVICES_AVAILABLE_FAILURE = 'SERVICES_AVAILABLE_FAILURE';

export const SELECTED_SERVICE = 'SELECTED_SERVICE';

export const PROVIDERS_AVAILABLE_REQUEST = 'PROVIDERS_AVAILABLE_REQUEST';
export const PROVIDERS_AVAILABLE_SUCCESS = 'PROVIDERS_AVAILABLE_SUCCESS';
export const PROVIDERS_AVAILABLE_FAILURE = 'PROVIDERS_AVAILABLE_FAILURE';

export const SELECTED_PROVIDERS = 'SELECTED_PROVIDERS';

export const SELECT_SLOT_TIME = 'SELECT_SLOT_TIME';

export const APP_REQ_ERR_MSG_HIDE = 'APP_REQ_ERR_MSG_HIDE';

export const CHANGE_PATIENT_ID = 'CHANGE_PATIENT_ID';

export const FETCH_USER_ONCOMING_APPS_REQUEST = 'FETCH_USER_ONCOMING_APPS_REQUEST';
export const FETCH_USER_ONCOMING_APPS_FULFILLED = 'FETCH_USER_ONCOMING_APPS_FULFILLED';
export const FETCH_USER_ONCOMING_APPS_REJECTED = 'FETCH_USER_ONCOMING_APPS_REJECTED';

export const EXISTING_ACCOUNT_MESSAGE_HIDE = 'EXISTING_ACCOUNT_MESSAGE_HIDE';

export const RESET_APPT_REQUEST = 'RESET_APPT_REQUEST';

export const FETCH_PRACTICES_FULFILLED = 'FETCH_PRACTICES_FULFILLED';
export const FETCH_PRACTICES_REJECTED = 'FETCH_PRACTICES_REJECTED';

export const SET_OFFICE_LOCATION = 'SET_OFFICE_LOCATION';
export const SET_PATIENT_REQUEST = 'SET_PATIENT_REQUEST';
export const GET_EXTERNAL_TOKEN = 'GET_EXTERNAL_TOKEN';

export const FETCH_APPT_APPROVAL_REQUEST = 'FETCH_APPT_APPROVAL_REQUEST';
export const FETCH_APPT_APPROVAL_FULFILLED = 'FETCH_APPT_APPROVAL_FULFILLED';
export const APPT_APPROVAL_ERROR = 'APPT_APPROVAL_ERROR';

export const FETCH_SEND_APPOINTMENT_FULFILLED_INACTIVE_PATIENT = 'FETCH_SEND_APPOINTMENT_FULFILLED_INACTIVE_PATIENT';

export const FETCH_USER_ACCOUNT_INFO_FULFILLED = 'FETCH_USER_ACCOUNT_INFO_FULFILLED';

export const AUTHORIZE_USER = 'AUTHORIZE_USER';

export const UTM_PARAMETERS = 'UTM_PARAMETERS';
