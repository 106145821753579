export function getUserField(userField) {
  return localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))[userField]
    : null;
}

export function getLocalObject(params) {
  return localStorage.getItem(params) !== null
    ? JSON.parse(localStorage.getItem(params))
    : {};
}

export function getLocalArray(params) {
  return localStorage.getItem(params) !== null
    ? JSON.parse(localStorage.getItem(params))
    : [];
}

export function getLocalString(userField) {
  return localStorage.getItem(userField) || '';
}

export function getSessionString(userField) {
  return sessionStorage.getItem(userField) || '';
}

export function getSessionStringNull(userField) {
  return sessionStorage.getItem(userField) !== null
    ? sessionStorage.getItem(userField)
    : null;
}

export function getSessionJsonWithDefault(userField, defaultValue) {
  return sessionStorage.getItem(userField) !== null
    ? JSON.parse(sessionStorage.getItem(userField))
    : defaultValue;
}

export function getSessionInt(userField) {
  return sessionStorage.getItem(userField) !== null
    ? parseInt(sessionStorage.getItem(userField), 10)
    : 0;
}

export function getLocalBoolWithDefault(userField, defaultValue) {
  return JSON.parse(localStorage.getItem(userField)) || defaultValue;
}

export function getSessionIntWithDefault(userField, defaultValue) {
  return JSON.parse(sessionStorage.getItem(userField)) || defaultValue;
}
