import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import homeInitialState from './inittialStates/homeInitialState';
import appointmentInitialState from './inittialStates/appointmentInitialState';
import userInitialState from './inittialStates/userInitialState';
import serviceInitialState from './inittialStates/serviceInitialState';
import providerInitialState from './inittialStates/providerInitialState';
import updatePasswordInitialState from './inittialStates/updatePasswordInitialState';
import appointmentApprovalInitialState from './inittialStates/appointmentApprovalInitialState';
import rootReducer from '../reducers';

const preloadState = {
  homeReducer: homeInitialState,
  userReducer: userInitialState,
  appointmentReducer: appointmentInitialState,
  serviceReducer: serviceInitialState,
  providerReducer: providerInitialState,
  updatePasswordReducer: updatePasswordInitialState,
  appointmentApprovalReducer: appointmentApprovalInitialState,
  notifications: [],
};

const isProduction = process.env.NODE_ENV === 'production';

function createCustomStore() {
  if (isProduction) {
    const middleware = applyMiddleware(thunk);
    return createStore(rootReducer, preloadState, middleware);
  }
  const middleware = applyMiddleware(thunk);
  let enhancer;
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    // eslint-disable-line
    enhancer = compose(
      middleware,
      window.__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line
    );
  } else {
    enhancer = compose(middleware);
  }
  return createStore(rootReducer, preloadState, enhancer);
}

export default createCustomStore;
