const getPatientInfo = (patientId, familyMembers) => {
  const patient = familyMembers.find((x) => x.patient.id === patientId);
  return {
    patientId: patient.patient.id,
    patientPmsId: patient.patient.pmsId,
    firstName: patient.patient.firstName,
    lastName: patient.patient.lastName,
    fullName: `${patient.patient.firstName} ${patient.patient.lastName}`,
    email: patient.patient.email,
    phone: patient.patient.phone,
  };
};

export default getPatientInfo;
