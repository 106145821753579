import camelcaseKeys from 'camelcase-keys-deep';
import cookie from 'react-cookies';
import {
  USER_AUTH_REQUEST,
  USER_AUTH_SUCCESS,
  USER_AUTH_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  FETCH_UPDATE_PASSWORD,
  FETCH_UPDATE_PASSWORD_FULFILLED,
  FETCH_UPDATE_PASSWORD_REJECTED,
  CHECK_USER_STATUS_REQUEST,
  CHECK_USER_STATUS_SUCCESS,
  CHECK_USER_STATUS_FAILURE,
  VALIDATE_METHOD_REQUEST,
  VALIDATE_METHOD_SUCCESS,
  VALIDATE_METHOD_FAILURE,
  USER_REGISTRY_VALIDATE_PROCESS_START,
  USER_REGISTRY_VALIDATE_PROCESS_END,
  VALIDATE_MODAL_SHOW,
  VALIDATE_MODAL_CLOSE,
  VALIDATE_MODAL_HIDE,
  CONFIRM_MODAL_SHOW,
  CONFIRM_MODAL_CLOSE,
  CONFIRM_MODAL_HIDE,
  CONFIRM_METHOD_REQUEST,
  CONFIRM_METHOD_SUCCESS,
  REGISTRY_COMPLETED_MESSAGE_SHOW,
  REGISTRY_COMPLETED_MESSAGE_HIDE,
  WRONG_PIN_MESSAGE_SHOW,
  WRONG_PIN_MESSAGE_HIDE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_CONFIRM_REQUEST,
  RESET_PASSWORD_CONFIRM_SUCCESS,
  RESET_PASSWORD_CONFIRM_FAILURE,
  RESET_PASSWORD_MODAL_SHOW,
  RESET_PASSWORD_MODAL_HIDE,
  RESET_PASSWORD_MODAL_CLOSE,
  RESET_PASSWORD_CONFIRM_MODAL_SHOW,
  RESET_PASSWORD_CONFIRM_MODAL_HIDE,
  RESET_PASSWORD_CONFIRM_MODAL_CLOSE,
  PASSWORD_SUCCESS_MODAL_SHOW,
  PASSWORD_SUCCESS_MODAL_HIDE,
  EXISTING_ACCOUNT_MESSAGE_HIDE,
  FETCH_USER_ACCOUNT_INFO_FULFILLED,
  UTM_PARAMETERS,
} from '../constants';
import { hostProtocol, hostIp, hostPort } from '../store/api';
import {
  handleErrors,
  handleRegisterErrors,
  handleLoginErrors,
  handleValidateErrors,
  handleConfirmErrors,
  handleResetPassErrors,
  handleUpdatePassErrors,
  headers,
  uri,
  setCookies,
  jwtHeaders,
} from '../utils/apiUtils';
import getClinicId from '../utils/getIdClinic';

export function login(data, history) {
  const body = JSON.stringify({
    Account: {
      email: data.email,
      password: data.password,
    },
  });
  return dataLogin(body, history, 'email');
}

export function loginUid(sessionId, history) {
  const body = JSON.stringify({
    Account: {
      session_id: sessionId,
      email: 'sessionId@smilescheduler.com',
      password: 'sessionIdType',
    },
  });
  return dataLogin(body, history, 'uid');
}

function dataLogin(body, history, type) {
  return (dispatch) => {
    dispatch({ type: USER_AUTH_REQUEST });
    fetch(uri('login'), {
      method: 'POST',
      headers,
      body,
    })
      .then(handleLoginErrors)
      .then((response) => response.json())
      .then((json) => {
        const jsonCamel = camelcaseKeys(json, { deep: true });
        const { token } = jsonCamel.data.item;

        fetch(uri('account'), {
          method: 'GET',
          headers: jwtHeaders(token),
        })
          .then(handleErrors)
          .then((response) => response.json())
          .then((jsonA) => {
            const jsonACamel = camelcaseKeys(jsonA, { deep: true });
            dispatch({
              type: FETCH_USER_ACCOUNT_INFO_FULFILLED,
              payload: camelcaseKeys(jsonACamel.data.item.account),
            });

            dispatch({ type: USER_AUTH_SUCCESS, payload: jsonCamel });
            const clinicId = getClinicId();
            localStorage.setItem('clinicId', clinicId);
            localStorage.setItem('token', token);
            localStorage.setItem(
              'user',
              JSON.stringify(jsonACamel.data.item.account),
            );
            localStorage.setItem('isAuthorized', true);
            localStorage.setItem(
              'accountInfo',
              JSON.stringify(jsonACamel.data.item.account),
            );
            setCookies(token);
            if (type === 'uid') {
              sessionStorage.setItem('sessionIdLogin', true);
            }
            history.push({
              pathname: '/profile',
              search: sessionStorage.getItem('_ga'),
            });
          });
      })
      .catch(() => {
        dispatch({ type: USER_AUTH_FAILURE, payload: '' });
        history.push({
          pathname: '/login',
          search: sessionStorage.getItem('_ga'),
        });
      });
  };
}

export function checkUserStatus(data) {
  const body = JSON.stringify({
    Account: {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phone,
      password: data.password,
      birthdate: data.birthday,
      address: data.address,
      city: data.city,
      state: data.state,
      zipcode: data.zipcode,
      utm_medium: data.utmMedium,
      utm_source: data.utmSource,
      utm_campaign: data.utmCampaign,
      utm_term: data.utmTerm,
      utm_content: data.utmContent,
    },
  });

  const searchParams = new URLSearchParams(window.location.search);
  const campaignId = searchParams.get('campaign_id');
  return function (dispatch) {
    dispatch({ type: CHECK_USER_STATUS_REQUEST });
    fetch(uri('register') + (campaignId ? `?campaign_id=${campaignId}` : ''), {
      method: 'POST',
      headers,
      body,
    })
      .then(handleRegisterErrors)
      .then((response) => response.json())
      .then((json) => {
        if (
          json.data.item.found === true
          && json.data.item.confirmed === true
        ) {
          dispatch({ type: CHECK_USER_STATUS_SUCCESS });
        } else {
          dispatch({ type: USER_REGISTRY_VALIDATE_PROCESS_START });
          dispatch({ type: VALIDATE_MODAL_SHOW, payload: json });
        }
      })
      .catch((error) => {
        dispatch({ type: CHECK_USER_STATUS_FAILURE, payload: error });
      });
  };
}

export function validateMethod(method, id) {
  const body = JSON.stringify({
    Account: {
      id,
    },
    method,
  });
  return function (dispatch) {
    dispatch({ type: VALIDATE_METHOD_REQUEST });
    fetch(uri('validate-request'), {
      method: 'POST',
      headers,
      body,
    })
      .then(handleValidateErrors)
      .then((response) => response.json())
      .then(() => {
        dispatch({ type: VALIDATE_METHOD_SUCCESS });
        dispatch({ type: VALIDATE_MODAL_HIDE, payload: method });
        dispatch({ type: USER_REGISTRY_VALIDATE_PROCESS_END });
        dispatch({ type: CONFIRM_MODAL_SHOW });
      })
      .catch((error) => {
        dispatch({ type: VALIDATE_METHOD_FAILURE, payload: error });
      });
  };
}

export function confirmCodeMethod(validationPin, id, data, history) {
  const body = JSON.stringify({
    Account: {
      id,
    },
    validation_pin: validationPin,
  });
  return function (dispatch) {
    dispatch({ type: CONFIRM_METHOD_REQUEST });
    fetch(uri('register-confirm'), {
      method: 'POST',
      headers,
      body,
    })
      .then(handleConfirmErrors)
      .then((response) => response.json())
      .then((json) => {
        dispatch({ type: CONFIRM_METHOD_SUCCESS });
        localStorage.setItem('token', json.data.item.token);
        dispatch({ type: CONFIRM_MODAL_HIDE, payload: json });
        dispatch({ type: REGISTRY_COMPLETED_MESSAGE_SHOW });
        setTimeout(() => {
          dispatch({ type: REGISTRY_COMPLETED_MESSAGE_HIDE });
        }, 3000);
        setTimeout(() => {
          const formData = {
            email: data.email,
            password: data.password,
          };
          dispatch(login(formData, history));
        }, 4000);
      })
      .catch((error) => {
        dispatch({ type: WRONG_PIN_MESSAGE_SHOW, payload: error });
      });
  };
}

export function logout(history, dispatch) {
  return function () {
    localStorage.clear();
    sessionStorage.clear();
    cookie.remove('token', { path: '/' });
    if (dispatch) {
      dispatch({ type: USER_LOGOUT_REQUEST });
      dispatch({ type: USER_LOGOUT_SUCCESS, payload: 'Good bye' });
    }
    history.push({
      pathname: '/login',
    });
  };
}

export function updatePassword(data, history) {
  /* eslint-enable */
  return function (dispatch) {
    dispatch({ type: FETCH_UPDATE_PASSWORD });
    fetch(`${hostProtocol}://${hostIp}:${hostPort}/api/password/update`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `CID=${encodeURIComponent(data.CID)}
        &password=${encodeURIComponent(data.password)}
        &password_confirm=${encodeURIComponent(data.confirmPassword)}
        &token=${encodeURIComponent(data.token)}`.replace(/\s/g, ''),
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        dispatch({
          type: FETCH_UPDATE_PASSWORD_FULFILLED,
          payload: camelcaseKeys(json, { deep: true }),
        });
        history.push({
          pathname: '/login',
          search: sessionStorage.getItem('_ga'),
        });
      })
      .catch((error) => dispatch({ type: FETCH_UPDATE_PASSWORD_REJECTED, payload: error }));
  };
}

export function resetPassword(data) {
  const body = JSON.stringify({
    Account: {
      email: data.email,
    },
    method: data.method,
  });
  return function (dispatch) {
    dispatch({ type: RESET_PASSWORD_REQUEST });
    fetch(uri('reset-password'), {
      method: 'POST',
      headers,
      body,
    })
      .then(handleResetPassErrors)
      .then((response) => response.json())
      .then((json) => {
        dispatch({
          type: RESET_PASSWORD_MODAL_HIDE,
          payload: camelcaseKeys(json, { deep: true }),
        });
        dispatch({ type: RESET_PASSWORD_CONFIRM_MODAL_SHOW });
      })
      .catch((error) => {
        dispatch({ type: RESET_PASSWORD_FAILURE, payload: error });
      });
  };
}

export function validateResetPassword(data) {
  const body = JSON.stringify({
    Account: {
      email: data.email,
      password: data.password,
    },
    validation_pin: data.validationPin,
  });
  return function (dispatch) {
    dispatch({ type: RESET_PASSWORD_CONFIRM_REQUEST });
    fetch(uri('reset-password-confirm'), {
      method: 'POST',
      headers,
      body,
    })
      .then(handleUpdatePassErrors)
      .then((response) => response.json())
      .then((json) => {
        dispatch({
          type: RESET_PASSWORD_CONFIRM_SUCCESS,
          payload: camelcaseKeys(json, { deep: true }),
        });
        dispatch({ type: RESET_PASSWORD_CONFIRM_MODAL_HIDE });
        dispatch({ type: PASSWORD_SUCCESS_MODAL_SHOW });
      })
      .catch((error) => {
        dispatch({ type: RESET_PASSWORD_CONFIRM_FAILURE, payload: error });
      });
  };
}

export function passwordSuccessModalClose(history) {
  return function (dispatch) {
    dispatch({ type: PASSWORD_SUCCESS_MODAL_HIDE });
    history.push({
      pathname: '/login',
      search: sessionStorage.getItem('_ga'),
    });
  };
}

export function validateModalClose() {
  return function (dispatch) {
    dispatch({ type: VALIDATE_MODAL_CLOSE });
  };
}

export function confirmModalClose() {
  return function (dispatch) {
    dispatch({ type: CONFIRM_MODAL_CLOSE });
  };
}

export function registryMessageModalClose() {
  return function (dispatch) {
    dispatch({ type: REGISTRY_COMPLETED_MESSAGE_HIDE });
  };
}

export function existingAccountMsgModalClose() {
  return function (dispatch) {
    dispatch({ type: EXISTING_ACCOUNT_MESSAGE_HIDE });
  };
}

export function resetPassModalShow() {
  return function (dispatch) {
    dispatch({ type: RESET_PASSWORD_MODAL_SHOW });
  };
}

export function resetPassModalClose() {
  return function (dispatch) {
    dispatch({ type: RESET_PASSWORD_MODAL_CLOSE });
  };
}

export function resetPassConfirmModalClose() {
  return function (dispatch) {
    dispatch({ type: RESET_PASSWORD_CONFIRM_MODAL_CLOSE });
  };
}

export function hideConfirmCodeErrorMsg() {
  return function (dispatch) {
    dispatch({ type: WRONG_PIN_MESSAGE_HIDE });
  };
}

export function utmParameters(data) {
  return function (dispatch) {
    dispatch({ type: UTM_PARAMETERS, payload: data });
  };
}
export function messageDispatch(type, title) {
  return function (dispatch) {
    dispatch({ type, title });
  };
}
