import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import Button from '../forms/Button';
import { FlexBlock, RootContainer } from '../styles';
import FormElem from '../forms/FormElem';
import { updatePassword } from '../../actions/authActions';
import Loading from '../Loading';

const DivCentered = styled.div`
  display: flex;
  flex: 1;
  width: auto;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;

  form {
    width: 100%;
    text-align: center;
  }
`;

const H2 = styled.h2`
  margin: 30px auto 50px;
`;

function UpdatePassword({ match, history }) {
  const dispatch = useDispatch();
  const themeColor = useSelector(
    (state) => state.homeReducer.clinic.styleOptions.themeColor,
  );
  const CID = useSelector((state) => state.homeReducer.CID);
  const errors = useSelector((state) => state.updatePasswordReducer.errors);
  const fetching = useSelector((state) => state.updatePasswordReducer.fetching);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { token } = match.params;

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      CID,
      password,
      confirmPassword,
      token,
    };
    dispatch(updatePassword(formData, history));
  };

  const onChange = (value, name) => {
    if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  if (fetching) {
    return (
      <RootContainer>
        <FlexBlock>
          <Loading themeColor={themeColor} innerText="Wait..." />
        </FlexBlock>
      </RootContainer>
    );
  }

  return (
    <RootContainer>
      <FlexBlock>
        <Col xs={12} md={6} lg={6}>
          <DivCentered>
            <H2>Restore password</H2>
            <form action="" onSubmit={onSubmit}>
              <FormElem
                type="password"
                valid={[
                  {
                    name: 'noEmpty',
                    message: errors ? errors.password : '',
                  },
                ]}
                name="password"
                onChange={(value, name, type) => onChange(value, name, type)}
                value={password}
                defaultValue={password}
                placeholder="Password"
              />
              <FormElem
                type="password"
                valid={[
                  {
                    name: 'noEmpty',
                    message: errors ? errors.password_confirm : '',
                  },
                ]}
                name="confirmPassword"
                onChange={(value, name, type) => onChange(value, name, type)}
                value={confirmPassword}
                defaultValue={confirmPassword}
                placeholder="Confirm password"
              />
              <Button styles="margin: 30px auto 0;" type="submit">
                Restore password
              </Button>
            </form>
          </DivCentered>
        </Col>
      </FlexBlock>
    </RootContainer>
  );
}

UpdatePassword.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(UpdatePassword);
