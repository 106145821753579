import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { LeftArrow, LinkArrows } from '../styles';
/* eslint-disable */
const ArrowButton = styled.div`
  a {
    color: ${(props) => props.fontColor};
  }

  a:before,
  a:after {
    display: none;
  }

  a:focus,
  a:hover {
    color: ${(props) => props.fontColor};
  }

  @media (max-width: 991px) {
    margin-top: 135px;
  }

  @media (max-width: 767px) {
    margin-top: 105px;
  }
`;

function StepsButton(props) {
  const history = useHistory();
  const location = useLocation();

  const setLinkForNavButton = (
    location,
    skipProvider,
    selectedServiceId,
    selectedProviderId,
    dateFrom,
    dateTo,
    request,
    themeColor,
    token,
    history,
    selectedTime,
    fetchingTime,
    openings,
    providers,
    preferredMorning,
    preferredAfternoon,
  ) => {
    const _ga = sessionStorage.getItem("_ga") || "";
    const storedObjectJSON = sessionStorage.getItem("selectedSlotTimeButton");
    const retrievedObject = storedObjectJSON
      ? JSON.parse(storedObjectJSON)
      : null;
    const checkResultVisibility = (e) => {
      e.preventDefault();
      toast.warning("Please Select Valid Time");
      dateFrom = null;
      dateTo = null;
    };

    switch (location.pathname) {
      case "/services":
        return (
          <>
            <LeftArrow
              color={themeColor}
              className="back-button"
              to={`/profile${_ga}`}
            >
              back
            </LeftArrow>

            <LinkArrows
              className="next-button"
              to="/providers"
              color={themeColor}
              onClick={(e) => {
                e.preventDefault();
                if (
                  selectedServiceId !== null &&
                  !Number.isNaN(selectedServiceId)
                ) {
                  history.push("/providers");
                } else {
                  props.dispatch(() => ({ title: "Select service" }));
                  toast.warning("Select service");
                }
              }}
            >
              next
            </LinkArrows>
          </>
        );
      case "/providers":
        return (
          <>
            <LeftArrow
              className="back-button"
              color={themeColor}
              to={`/services${_ga}`}
            >
              back
            </LeftArrow>
            {providerNext(providers, selectedProviderId, history)}
          </>
        );
      case "/filter":
        if (fetchingTime) {
          return null;
        }
        if (
          (dateFrom !== null &&
            dateTo !== null &&
            dateFrom !== "null" &&
            dateTo !== "null") ||
          preferredMorning ||
          preferredAfternoon
        ) {
          if (retrievedObject === null) {
            return (
              <div>
                <LeftArrow
                  className="back-button"
                  color={themeColor}
                  to="/providers"
                >
                  back
                </LeftArrow>
                <LinkArrows
                  className="next-button"
                  color={themeColor}
                  onClick={checkResultVisibility}
                >
                  next
                </LinkArrows>
              </div>
            );
          }

          return (
            <div>
              <LeftArrow
                className="back-button"
                color={themeColor}
                to="/providers"
              >
                back
              </LeftArrow>
              <LinkArrows
                className="next-button"
                color={themeColor}
                to="/results"
                onClick={(e) => {
                  e.preventDefault();
                  if (selectedTime !== "") {
                    history.push({
                      pathname: "/results",
                      search: sessionStorage.getItem("_ga"),
                    });
                  } else {
                    props.dispatch(() => ({ title: "Select time" }));
                    toast.warning("Select time");
                  }
                }}
              >
                next
              </LinkArrows>
            </div>
          );
        }
        if (skipProvider) {
          return (
            <div>
              <LeftArrow
                className="back-button"
                color={themeColor}
                to={`/providers${_ga}`}
              >
                back
              </LeftArrow>
            </div>
          );
        }

        return (
          <div>
            <LeftArrow
              className="back-button"
              color={themeColor}
              to={`/providers${_ga}`}
            >
              back
            </LeftArrow>
          </div>
        );
      case "/time":
        return (
          <div>
            <LeftArrow
              className="back-button"
              color={themeColor}
              to={`/filter${_ga}`}
            >
              back
            </LeftArrow>
            {timeNext(openings, selectedTime)}
          </div>
        );
      default:
        return null;
    }
  };

  const timeNext = (openings, selectedTime) => {
    if (openings.length > 0) {
      return (
        <LinkArrows
          to="/results"
          onClick={(e) => {
            e.preventDefault();
            if (selectedTime !== "") {
              history.push({
                pathname: "/results",
                search: sessionStorage.getItem("_ga"),
              });
            } else {
              props.dispatch(() => ({ title: "Select time" }));
              toast.warning("Select time");
            }
          }}
        >
          next
        </LinkArrows>
      );
    }
    return null;
  };

  const providerNext = (providers, selectedProviderId) => {
    if (providers.length > 0) {
      return (
        <LinkArrows
          className="next-button"
          color={themeColor}
          to="/filter"
          onClick={(e) => {
            e.preventDefault();
            selectedProviderId !== null && !isNaN(selectedProviderId)
              ? history.push({
                  pathname: "/filter",
                  search: sessionStorage.getItem("_ga"),
                })
              : props.dispatch(() => ({ title: "Select provider" }));
            toast.warning("Select provider");
          }}
        >
          next
        </LinkArrows>
      );
    }
    return null;
  };

  const {
    themeColor,
    selectedServiceId,
    selectedProviderId,
    request,
    token,
    selectedTime,
    fontColor,
    fetchingTime,
    openings,
    providers,
    preferredMorning,
    preferredNoon,
    preferredEvening,
    skipProvider,
  } = props;

  return (
    <div className="sticky-buttons">
      <ArrowButton fontColor={fontColor}>
        {setLinkForNavButton(
          location,
          skipProvider,
          selectedServiceId,
          selectedProviderId,
          request.dateFrom,
          request.dateTo,
          request,
          themeColor,
          token,
          history,
          selectedTime,
          fetchingTime,
          openings,
          providers,
          preferredMorning,
          preferredNoon,
          preferredEvening,
        )}
      </ArrowButton>
    </div>
  );
}

StepsButton.propTypes = {
  location: PropTypes.object.isRequired,
  themeColor: PropTypes.string.isRequired,
  selectedServiceId: PropTypes.number,
  selectedProviderId: PropTypes.number,
  request: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  preferredMorning: PropTypes.bool,
  preferredNoon: PropTypes.bool,
  preferredEvening: PropTypes.bool,
  selectedTime: PropTypes.string,
  fontColor: PropTypes.string.isRequired,
  fetchingTime: PropTypes.bool,
  openings: PropTypes.array,
  providers: PropTypes.array,
  skipProvider: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  request: state.appointmentReducer.request,
  fetchedTime: state.appointmentReducer.fetchedTime,
  token: state.userReducer.token,
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontColor: state.homeReducer.clinic.styleOptions.fontColor,
  selectedServiceId: state.appointmentReducer.request.serviceId,
  selectedProviderId: state.appointmentReducer.request.providerId,
  selectedTime: state.appointmentReducer.selectedTime,
  openings: state.appointmentReducer.openings,
  providers: state.providerReducer.providers,
  preferredMorning: state.appointmentReducer.preferredMorning,
  preferredNoon: state.appointmentReducer.preferredNoon,
  preferredEvening: state.appointmentReducer.preferredEvening,
  skipProvider: state.appointmentReducer.skipProvider,
});

export default connect(mapStateToProps)(StepsButton);
