const message = (messageState, messagePayload) => {
  let messageNew;
  if (messageState !== messagePayload) {
    messageNew = messagePayload;
  } else if (messageState !== '' && messageState === messagePayload) {
    messageNew = `${messagePayload} `;
  } else {
    messageNew = `${messageState} `;
  }
  return messageNew;
};

export default message;
