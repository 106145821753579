import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProfileAddFamilyMember from './ProfileAddFamilyMember';
import Loading from '../Loading';
import {
  getUserFamilyMembers,
  getPracticesList,
  authorizeUser,
  setPatientRequest,
} from '../../actions/profileActions';
import { getMainAccountPatientId } from '../forms/utils';
import { UserProfile } from './UserProfile';
import { ProfileEdit } from './ProfileEdit';
import ViewUpcomingSchedule from '../appointmentSteps/ViewUpcomingSchedule';

class Profile extends Component {
  /* TODO hide form on submit success and show on Errors */

  constructor(props) {
    super(props);
    this.state = {
      familyMembers: [],
      patientId: props.patientId,
      activeTab: 'Schedule',
      profileChanged: '',
    };
    sessionStorage.setItem('serviceId', 0);
  }

  UNSAFE_componentWillMount() {
    let token;
    if (this.props.token) {
      token = this.props.token;
    }
    if (!token && localStorage.getItem('token')) {
      token = localStorage.getItem('token');
    }
    if (!token) {
      this.props.history.push('/login');
    }
    this.props.dispatch(
      getUserFamilyMembers(token, this.props.history),
    );
    this.props.dispatch(authorizeUser());
    if (this.props.isMultiofficeEnabled) {
      this.props.dispatch(
        getPracticesList(token, this.props.hashBid),
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.familyMembers !== this.props.familyMembers) {
      this.setState((prevState) => (
        {
          ...prevState,
          activeTab: 'Schedule',
          familyMembers: nextProps.familyMembers,
        }
      ));
      if (this.props.patientId < 1 || !this.props.patientId) {
        this.props.dispatch(
          setPatientRequest(
            nextProps.familyMembers.length > 0
              ? getMainAccountPatientId(nextProps.familyMembers)
              : 0,
          ),
        );
      }
    }

    if (nextProps.patientId !== this.props.patientId) {
      this.setState((prevState) => (
        {
          ...prevState,
          profileChanged: 'profile',
          patientId: nextProps.patientId,
        }
      ));
    }
  }

  handleTabSelect = (tabKey) => {
    this.setState((prevState) => ({
      ...prevState,
      activeTab: tabKey,
    }));
  };

  render() {
    const {
      themeColor,
      fontColor,
      history,
      font,
      errors,
      token,
      fetchingFamilyMembers,
      fetchedFamilyMembers,
      editSuccess,
      accountInfo,
      practicesList,
      practicesOptions,
      familyMembersOptions,
      hashBid,
      isMultiofficeEnabled,
      mainOfficeInfo,
    } = this.props;
    const { familyMembers, patientId } = this.state;
    if (fetchingFamilyMembers) {
      return <Loading innerText="Loading..." themeColor={themeColor} />;
    }
    return (
      <Container>
        <Row className="py-4">
          <Col xs={12} sm={8} md={12} lg={4}>
            <div className="Profile">
              <UserProfile
                themeColor={themeColor}
                fontColor={fontColor}
                accountInfo={accountInfo}
                familyMembers={familyMembers}
                familyMembersOptions={familyMembersOptions}
                patientId={patientId}
                mainOfficeInfo={mainOfficeInfo}
              />
            </div>
          </Col>
          <Col xs={12} sm={8} md={12} lg={8}>
            <div className="v-line">
              <Tabs
                activeKey={this.state.activeTab}
                onSelect={this.handleTabSelect}
                defaultActiveKey="FamilyMembers"
                id="fill-tab-example"
                className="mb-3 profile-tabs"
                fill
              >
                <Tab eventKey="Schedule" title="View Upcoming Schedule">
                  {this.state.activeTab === 'Schedule' && <ViewUpcomingSchedule />}
                </Tab>
                <Tab eventKey="FamilyMembers" title="Add Family Member">
                  {this.state.activeTab === 'FamilyMembers'
                    && (
                      <div>
                        <ProfileAddFamilyMember
                          fontColor={fontColor}
                          themeColor={themeColor}
                          errors={errors}
                          token={token}
                          editSuccess={editSuccess}
                          familyMembers={familyMembers}
                          patientId={patientId}
                          fetchedFamilyMembers={fetchedFamilyMembers}
                          fetchedFamilyMembersCount={fetchedFamilyMembers}
                          history={history}
                        />
                      </div>
                    )}
                </Tab>
                <Tab eventKey="profile" title="Edit Profile">
                  {this.state.activeTab === 'profile' || this.state.profileChanged
                    ? (
                      <ProfileEdit
                        themeColor={themeColor}
                        fontColor={fontColor}
                        accountInfo={accountInfo}
                        font={font}
                        errors={errors}
                        token={token}
                        editSuccess={editSuccess}
                        history={history}
                        familyMembers={familyMembers}
                        practicesList={practicesList}
                        practicesOptions={practicesOptions}
                        familyMembersOptions={familyMembersOptions}
                        patientId={patientId}
                        hashBid={hashBid}
                        isMultiofficeEnabled={isMultiofficeEnabled}
                        mainOfficeInfo={mainOfficeInfo}
                      />
                    ) : null}
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

Profile.propTypes = {
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  photo: PropTypes.string, //eslint-disable-line
  history: PropTypes.object.isRequired,
  font: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  familyMembers: PropTypes.array.isRequired,
  fetchingFamilyMembers: PropTypes.bool.isRequired,
  editSuccess: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  accountInfo: PropTypes.object.isRequired,
  hashBid: PropTypes.string,
  practicesList: PropTypes.array,
  practicesOptions: PropTypes.array,
  familyMembersOptions: PropTypes.array.isRequired,
  isMultiofficeEnabled: PropTypes.bool.isRequired,
  mainOfficeInfo: PropTypes.object.isRequired,
  patientId: PropTypes.number.isRequired,
};

Profile.defaultProps = {
  familyMembers: [],
  accountInfo: {},
  familyMembersOptions: [],
  hashBid: '',
  practicesList: [],
  practicesOptions: [],
};

const mapStateToProps = (state) => ({
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontColor: state.homeReducer.clinic.styleOptions.fontColor,
  font: state.homeReducer.clinic.styleOptions.font,
  errors: state.homeReducer.errors,
  familyMembers: state.userReducer.familyMembers,
  familyMembersOptions: state.userReducer.familyMembersOptions,
  token: state.userReducer.token,
  fetchingFamilyMembers: state.userReducer.fetchingFamilyMembers,
  fetchedFamilyMembers: state.userReducer.fetchedFamilyMembers,
  editSuccess: state.userReducer.editSuccess,
  accountInfo: state.userReducer.accountInfo,
  hashBid: state.appointmentReducer.request.hashBid,
  practicesList: state.homeReducer.practicesList,
  practicesOptions: state.homeReducer.practicesOptions,
  isMultiofficeEnabled:
  state.homeReducer.clinic.clinicInfo.isMultiofficeEnabled,
  mainOfficeInfo: state.homeReducer.clinic.clinicInfo,
  patientId: state.appointmentReducer.request.patientId,
});

export default withRouter(connect(mapStateToProps)(Profile));
