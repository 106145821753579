import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withRouter, Switch, Route, Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Modal, ModalBody, ModalHeader,
} from 'react-bootstrap';
import Notifications from 'react-notification-system-redux';
import cookie from 'react-cookies';
import { detect } from 'detect-browser';
import styled from 'styled-components';
import {
  NotificationsStyles,
} from '../styles';
import PageHeader from './PageHeader';
import Services from '../appointmentSteps/Services';
import Providers from '../appointmentSteps/providers/Providers';
import Filter from '../appointmentSteps/Filter';
import AuthMainPage from '../auth/AuthMainPage';
import Time from '../appointmentSteps/Time';
import Result from '../appointmentSteps/Result';
import PrivateRoute from '../../routes/privateRoute';
import Success from '../appointmentSteps/Success';
import Profile from '../profile/Profile';
import Review from '../appointmentSteps/providers/Review';
import UpdatePassword from './UpdatePassword';
import ApproveAppointment from './ApproveAppointment';
import ButtonsModal from '../forms/ButtonsModal';
import { setBrowserNotSupportCookies } from '../../utils/apiUtils';
import LeadGenLogin from '../auth/LeadGenLogin';

const browser = detect();
const browsersName = ['chrome', 'firefox', 'ie', 'edge', 'ios', 'safari', 'yandexbrowser', 'crios'];
const browsersSupport = [];
browsersSupport.crios = 63;
browsersSupport.chrome = 63;
browsersSupport.firefox = 65;
browsersSupport.ie = 11;
browsersSupport.edge = 17;
browsersSupport.ios = 11;
browsersSupport.safari = 11;
browsersSupport.yandexbrowser = 18;

const ButtonModalDiv = styled.div`
    margin-top: 20px;
`;

const DivModal = styled.div`
  color: ${(props) => props.fontColor || 'rgb(0, 0, 0)'};
`;

class Page extends Component {
  constructor() {
    super();
    this.state = {
      browserModalShow: !cookie.load('browserNotSupported'),
    };
  }

  browserNotSupported = (fontColor) => (
    <Modal
      aria-labelledby="contained-modal-title-sm"
      backdrop="static"
      show={this.state.browserModalShow}
    >
      <DivModal fontColor={fontColor}>
        <ModalHeader>
          <b>Your Browser is Not Supported</b>
        </ModalHeader>
        <ModalBody>
          {`To take full advantage of the application, use a modern browser,
          or click "continue" to proceed to schedule an appointment`}
          <ButtonModalDiv>
            <ButtonsModal type="submit" onClick={() => this.browserModalHide()}>
              Continue
            </ButtonsModal>
          </ButtonModalDiv>
        </ModalBody>
      </DivModal>
    </Modal>
  );

  browserModalHide = () => {
    this.setState({
      browserModalShow: false,
    });
    setBrowserNotSupportCookies();
  };

  render() {
    const {
      isAuthorized,
      selectedServiceId,
      clinic,
      notifications,
      externalToken,
    } = this.props;
    const isTokenAuthorized = ((localStorage.getItem('isAuthorized')) || (cookie.load('token'))) ? true : isAuthorized;
    let htmlMainTitle = '';
    if (!isTokenAuthorized) {
      htmlMainTitle = (externalToken) ? 'Appointment Requested for Approval' : 'Schedule Your Appointment';
    }
    let browserNotSupportedModal = null;
    if (this.state.browserModalShow) {
      if (browsersName.includes(browser.name)) {
        if (parseInt(browser.version, 10) < browsersSupport[browser.name]) {
          const fontColor = Object.keys(clinic).length > 0 && clinic.styleOptions.fontColor;
          browserNotSupportedModal = this.browserNotSupported(fontColor);
        }
      } else {
        const fontColor = Object.keys(clinic).length > 0 && clinic.styleOptions.fontColor;
        browserNotSupportedModal = this.browserNotSupported(fontColor);
      }
    }
    const _ga = sessionStorage.getItem('_ga') ? sessionStorage.getItem('_ga') : '';
    return (
      <>
        {browserNotSupportedModal}
        {isAuthorized && (
          <PageHeader
            clinicTitle={clinic.clinicInfo.title}
            clinicPhone={clinic.clinicInfo.phone}
            clinicLogoSrc={clinic.styleOptions.logo}
            html={htmlMainTitle}
            isAuthorized={isTokenAuthorized}
          />
        )}
        <Switch>
          <Route
            exact
            path="/"
            // eslint-disable-next-line
            render={() => isTokenAuthorized
              ? <Redirect to={`/profile${_ga}`} />
              : <Redirect to={`/login${_ga}`} />}
          />
          <PrivateRoute
            path="/services"
            component={Services}
            authenticated={isAuthorized}
          />
          <PrivateRoute
            path="/providers"
            component={
              selectedServiceId !== null && !Number.isNaN(selectedServiceId)
                ? Providers
                : () => <Redirect to={`/services${_ga}`} />
            }
            authenticated={isAuthorized}
          />

          <PrivateRoute
            path="/filter"
            component={Filter}
            authenticated={isAuthorized}
          />
          <PrivateRoute
            path="/time"
            component={Time}
            authenticated={isAuthorized}
          />
          <PrivateRoute
            path="/results"
            component={Result}
            authenticated={isAuthorized}
          />
          <PrivateRoute
            path="/success"
            component={Success}
            authenticated={isAuthorized}
          />

          <PrivateRoute
            path="/profile"
            component={Profile}
            authenticated={isTokenAuthorized}
          />

          <PrivateRoute
            path="/reviews"
            component={Review}
            authenticated={isAuthorized}
          />

          <Route
            path="/schedule"
            render={() => <LeadGenLogin />}
          />

          <Route path="/registry" render={() => <AuthMainPage clinic={clinic} isNewUser />} />
          <Route
            path="/login"
            render={() => {
              if (isTokenAuthorized) {
                return <Redirect to={`/profile${_ga}`} />;
              }
              return <AuthMainPage clinic={clinic} isNewUser={false} />;
            }}
          />

          <Route
            path="/updatePassword/:token"
            render={() => <UpdatePassword />}
          />

          <Route
            path="/confirm-approval-request"
            render={() => <ApproveAppointment />}
          />

          <Route
            path="*"
            render={() => {
              if (isTokenAuthorized) {
                return <Redirect to={`/profile${_ga}`} />;
              }
              return <AuthMainPage clinic={clinic} isNewUser={false} />;
            }}
          />
        </Switch>
        <Notifications
          notifications={notifications}
          style={NotificationsStyles}
        />
      </>
    );
  }
}

Page.propTypes = {
  clinic: PropTypes.object.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  selectedServiceId: PropTypes.number, //eslint-disable-line
  notifications: PropTypes.array.isRequired,
  externalToken: PropTypes.string, //eslint-disable-line
};

const mapStateToProps = (state) => ({
  isAuthorized: state.userReducer.isAuthorized,
  selectedServiceId: state.serviceReducer.selectedServiceId,
  notifications: state.notifications,
  externalToken: state.homeReducer.externalToken,
});

export default withRouter(connect(mapStateToProps)(Page));
