import {
  PROVIDERS_AVAILABLE_SUCCESS,
  PROVIDERS_AVAILABLE_REQUEST,
  SELECTED_PROVIDERS,
  RESET_APPT_REQUEST,
} from '../constants';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case PROVIDERS_AVAILABLE_REQUEST: {
      return {
        ...state,
        fetchingProviders: true,
      };
    }

    case PROVIDERS_AVAILABLE_SUCCESS: {
      return {
        ...state,
        fetchingProviders: false,
        providers: action.payload,
      };
    }

    case SELECTED_PROVIDERS: {
      return {
        ...state,
        selectedProviderId: action.payload.data.providerId,
      };
    }

    case RESET_APPT_REQUEST: {
      return {
        ...state,
        selectedProviderId: null,
      };
    }

    default:
      return state;
  }
};
