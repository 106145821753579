import camelcaseKeys from 'camelcase-keys-deep';
import {
  PROVIDERS_AVAILABLE_REQUEST,
  PROVIDERS_AVAILABLE_SUCCESS,
  PROVIDERS_AVAILABLE_FAILURE,
} from '../constants';
import { uriWithParams, jwtHeaders, handleErrors } from '../utils/apiUtils';
import { logout } from './authActions';

export default function getProviders(token, data) {
  return (dispatch) => {
    dispatch({ type: PROVIDERS_AVAILABLE_REQUEST });
    fetch(uriWithParams('providers', data), {
      method: 'GET',
      headers: jwtHeaders(token),
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((json) => {
        const jsonCamel = camelcaseKeys(json, { deep: true });
        let providers = [];
        if (
          jsonCamel.code !== 1001
          && jsonCamel.data.items
          && jsonCamel.data.items.length > 0
        ) {
          providers = jsonCamel.data.items;
        }
        dispatch({ type: PROVIDERS_AVAILABLE_SUCCESS, payload: providers });
        sessionStorage.setItem('providers', JSON.stringify(providers));
      })
      .catch((error) => {
        if (error.data.errors.error === 'Token is expired') {
          dispatch(logout(history));
        } else {
          dispatch({ type: PROVIDERS_AVAILABLE_FAILURE, payload: error });
        }
      });
  };
}
