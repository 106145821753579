import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'whatwg-fetch';
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'rsuite/dist/rsuite.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assests/sass/style.scss';
import './utils/polyfills';
import registerServiceWorker from './registerServiceWorker';
import App from './App';

const root = createRoot(document.getElementById('root'));
root.render(<App />);
registerServiceWorker();
