import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

const Buttons = styled.button`
  background-color: ${(props) => props.color || 'rgb(70, 223, 212)'};
  font-family: Roboto;
  border: 0;
  border-radius: 44px;
  color: rgb(255, 255, 255);
  outline: none;
  font-size: 1.3em;
  padding: 18px 30px;
  width: 50%;
  margin: unset;
  margin-top: unset;
  margin-right: 500px;
  margin-bottom: unset;
  margin-left: unset;
  ${(props) => props.styles} &:hover {
    opacity: 6;
  }

  @media (max-width: 200px) {
    font-size: 1em;
  }
`;

function ButtonsModal({
  type,
  onClick,
  disabled,
  children,
  themeColor,
  styles,
}) {
  return (
    <Buttons
      color={themeColor}
      disabled={disabled}
      onClick={onClick}
      type={type}
      styles={styles}
    >
      {children}
    </Buttons>
  );
}

ButtonsModal.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func, //eslint-disable-line
  type: PropTypes.string,
  themeColor: PropTypes.string.isRequired,
  styles: PropTypes.string,
};

ButtonsModal.defaultProps = {
  disabled: false,
  type: 'button',
  styles: '',
};

const mapStateToProps = (state) => ({
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
});

export default connect(mapStateToProps)(ButtonsModal);
