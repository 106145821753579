import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

const Title = styled.h4`
  font-family: Roboto;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 700;
  color: ${(props) => props.fontColor || '#424242'};
  opacity: 1;
  text-transform: capitalize;

  @media (max-width: 767px) {
    width: 60%;
    margin: 0 auto;
    font-size: 1.5em;
  }
`;

const Description = styled.p`
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => props.fontColor || '#424242'};

  @media (max-width: 991px) {
    width: 60%;
    margin: 0 auto;
  }

  @media (max-width: 767px) {
    width: 90%;
    font-size: 1.1em;
  }
`;

function StepHeader({
  title, description, fontColor, message,
}) {
  return (
    <>
      <Title fontColor={fontColor}>{title}</Title>
      <Description className={message ? 'mb-0' : ''} fontColor={fontColor}>{description}</Description>
      {message && <Description className="m-0 fw-bold" fontColor="red">{message}</Description>}
    </>
  );
}

StepHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  message: PropTypes.string,
};

const mapStateToProps = (state) => ({
  fontColor: state.homeReducer.clinic.styleOptions.fontColor,
});

export default connect(mapStateToProps)(StepHeader);
