import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import UserInfoFields from '../forms/UserInfoFields';
import { addUserFamilyMember } from '../../actions/profileActions';
import { clearErrors } from '../../actions/homeActions';
import ProfileFamilyMembers from './ProfileFamilyMembers';
import Loading from '../Loading';
import {
  validateFormData,
  formatPhoneNumber,
  sanitizeNames,
  sanitizePhone,
} from '../forms/utils';
import issueIcon from '../../assests/images/issueIcon.svg';

const AddButton = styled(Button)`
  padding: 8px 16px;
  color: #fff;
  text-decoration: none;
  border-radius: 25px;
  background-color: ${(props) => props.themeColor || '#258ADA'};
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
`;

const ResetButton = styled(Button)`
  padding: 8px 16px;
  color: ${(props) => props.themeColor || '#258ADA'};
  text-decoration: none;
  border-radius: 25px;
  box-shadow: inset 0px 0px 0px 1px ${(props) => props.themeColor || '#258ADA'};
  background-color: transparent;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  box-sizing: border-box;
`;

function ProfileAddFamilyMember(props) {
  const dispatch = useDispatch();
  const [masterUser, setMasterUser] = useState(false);
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    birthday: '',
    phone: '',
    email: '',
    errors: props.errors,
    relationShip: null,
    formMsgErrors: {},
  });

  useEffect(() => {
    if (props.familyMembers) {
      const patient = props.familyMembers.find((x) => x.patient.id === props.patientId);
      if (patient && patient.patient && patient.patient.isMaster !== undefined) {
        const isMasterUser = patient.patient.isMaster;
        setMasterUser(isMasterUser);
      }
    }
  }, [props.patientId, props.familyMembers]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (state.phone !== '') {
      setState((prevState) => ({
        ...prevState,
        phone: formatPhoneNumber(state.phone),
      }));
    }
    const formData = {
      firstName: state.firstName,
      lastName: state.lastName,
      birthday: state.birthday,
      phone: state.phone,
      email: state.email,
    };
    if (!Object.entries(validateForm(formData)).length > 0) {
      formData.birthday = moment
        .utc(state.birthday, 'MM-DD-YYYY')
        .format('YYYY-MM-DD');
      toggleAddUserBlock(e);
      dispatch(addUserFamilyMember(formData, props.token));
    }
  };

  const onChange = (value, name) => {
    let inputValue = value;
    if (name === 'firstName' || name === 'lastName') {
      inputValue = sanitizeNames(value);
    }
    if (name === 'phone') {
      inputValue = sanitizePhone(value);
    }
    if (name === 'phone' && value.length > 9 && sanitizePhone(value)) {
      inputValue = formatPhoneNumber(sanitizePhone(value));
    }

    setState((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));

    setState((prevState) => ({
      ...prevState,
      formMsgErrors: {
        ...prevState.formMsgErrors,
        [name]: '',
      },
    }));
  };

  const displayFormErrors = (formElements, errors) => {
    Object.keys(formElements).forEach((key) => {
      setState((prevState) => ({
        ...prevState,
        formMsgErrors: {
          ...prevState.formMsgErrors,
          [key]: errors[key] || '',
        },
      }));
    });
  };

  const validateForm = (formData) => {
    const formErrors = validateFormData(formData, 'family');
    displayFormErrors(formData, formErrors);
    return formErrors;
  };

  const toggleAddUserBlock = () => {
    setState((prevState) => ({
      ...prevState,
      isAddUserForm: !prevState.isAddUserForm,
    }));
  };

  const handleDayChange = (birthday) => {
    setState((prevState) => ({
      ...prevState,
      formMsgErrors: {
        ...prevState.formMsgErrors,
        birthday: '',
      },
    }));
    setState((prevState) => ({
      ...prevState,
      birthday: moment(birthday, 'MM-DD-YYYY', true),
    }));
  };
  const renderFamilyMembersBlock = (
    token,
    familyMembers,
    themeColor,
    fetchingFamilyMembers,
    fetchedFamilyMembers,
    history,
  ) => {
    if (fetchingFamilyMembers) {
      return <Loading innerText="Loading..." themeColor={themeColor} />;
    }

    if (familyMembers.length > 1) {
      return (
        <ProfileFamilyMembers
          token={token}
          members={familyMembers}
          colorSuccess={themeColor}
          editSuccess={props.editSuccess}
          history={history}
        />
      );
    }
    return null;
  };

  if (masterUser) {
    return (
      <div>
        <form onSubmit={onSubmit}>
          <Col xs={12} className="my-3">
            <UserInfoFields
              data={state}
              onChange={onChange}
              fontColor={props.fontColor}
              themeColor={props.themeColor}
              errors={props.errors.length ? props.errors : state.formMsgErrors}
              handleDayChange={handleDayChange}
            />
          </Col>
          <Row className="align-items-center gap-4">
            <Col xs={12} md={1}>
              <AddButton type="submit" themeColor={props.themeColor}>
                Add
              </AddButton>
            </Col>
            <Col xs={12} md={2}>
              <ResetButton
                themeColor={props.themeColor}
                type="reset"
                onClick={(e) => {
                  toggleAddUserBlock(e);
                  dispatch(clearErrors());
                }}
              >
                Cancel
              </ResetButton>
            </Col>
          </Row>
        </form>
        {renderFamilyMembersBlock(
          props.token,
          props.familyMembers,
          props.themeColor,
          props.fetchingFamilyMembers,
          props.fetchedFamilyMembers,
          props.history,
        )}
      </div>
    );
  }

  return (
    <div className="text-center child-message">
      <img width="160px" src={issueIcon} alt="issueimage" />
      <div><p>You can only add family members from a main account.Please switch to the main(parent) account.</p></div>
    </div>
  );
}

ProfileAddFamilyMember.propTypes = {
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  familyMembers: PropTypes.array.isRequired,
  patientId: PropTypes.number.isRequired,
  fetchingFamilyMembers: PropTypes.bool.isRequired,
  fetchedFamilyMembers: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.homeReducer.errors,
  fetchingFamilyMembers: state.userReducer.fetchingFamilyMembers,
  fetchedFamilyMembers: state.userReducer.fetchedFamilyMembers,
});

export default connect(mapStateToProps)(ProfileAddFamilyMember);
