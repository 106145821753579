import React, { useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import cookie from 'react-cookies';
import styled from 'styled-components';
import { NavLink, useHistory } from 'react-router-dom';
import SignIn from './SignInForm';
import SignUp from './SignUpForm';
import PageHeader from '../page/PageHeader';
import SideImage from './SideImage';
import { ChangeRoot } from '../styles';

import { clearErrors } from '../../actions/homeActions';

export const HeaderTitle = styled.h4`
  color: ${(props) => props.color || '#424242'};
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  text-transform: capitalize;
  @media (max-width: 767px) {
    text-align: center;
    font-size: 1.4em;
  }
`;

HeaderTitle.displayName = 'HeaderTitle';

export default function AuthMainPage(props) {
  const imageUrl = '/assests/signinSideImage.svg';
  const {
    isAuthorized, externalToken, themeColor, fontColor,
  } = useSelector(
    (state) => ({
      isAuthorized: state.userReducer.isAuthorized,
      selectedServiceId: state.serviceReducer.selectedServiceId,
      notifications: state.notifications,
      externalToken: state.homeReducer.externalToken,
      themeColor: state.homeReducer.clinic.styleOptions.themeColor,
      fontColor: state.homeReducer.clinic.styleOptions.fontColor,
    }),
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [html, setHtml] = useState('');

  const isTokenAuthorized = localStorage.getItem('isAuthorized') || cookie.load('token')
    ? true
    : isAuthorized;
/*eslint-disable*/
  const checkTitle = useCallback((title) => {
    if (!title) {
      if (!isTokenAuthorized) {
        externalToken
          ? setHtml('Appointment Requested for Approval')
          : setHtml('Schedule Your Appointment');
      }
    } else {
      setHtml(title);
    }
  }, []);
  /* eslint-enable */
  const _ga = sessionStorage.getItem('_ga') || '';
  return (
    <Row className="justify-content-center align-items-stretch h-100">
      <Col
        xs={{ span: 12, order: 2 }}
        md={{ span: 6, order: 1 }}
        className="bg-primary"
      >
        <div className="d-flex justify-content-center align-items-center h-100">
          <SideImage src={imageUrl} />
        </div>
      </Col>
      <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
        <PageHeader
          clinicTitle={props.clinic.clinicInfo.title}
          clinicPhone={props.clinic.clinicInfo.phone}
          clinicLogoSrc={props.clinic.styleOptions.logo}
          html={html}
          isAuthorized={isTokenAuthorized}
        />
        <ChangeRoot linkhovercolor={themeColor} fontcolor={fontColor}>
          <NavLink activeClassName="active" to={`/login${_ga}`}>
            Existing Patient
          </NavLink>
          <NavLink
            activeClassName="active"
            to="/registry"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: '/registry',
                search: sessionStorage.getItem('_ga'),
              });
              dispatch(clearErrors());
            }}
          >
            New Patient
          </NavLink>
        </ChangeRoot>
        {props.isNewUser ? (
          <SignUp sendtitle={checkTitle} />
        ) : (
          <SignIn sendtitle={checkTitle} />
        )}
      </Col>
    </Row>
  );
}
