import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import getClinicId from './utils/getIdClinic';
import createCustomStore from './store/store';
import Layout from './layouts/Layout';
import { fetchClinic, getExternalToken } from './actions/homeActions';
import { messageDispatch } from './actions/authActions';
import 'react-toastify/dist/ReactToastify.css';

const store = createCustomStore();
const clinicId = getClinicId();
let currentValueS = '';
let currentValueE = '';

function App() {
  useEffect(() => {
    const unsubscribeSuccess = store.subscribe(handleChangeSuccess);
    const unsubscribeError = store.subscribe(handleChangeError);

    return () => {
      unsubscribeSuccess();
      unsubscribeError();
    };
  }, []);

  const handleChangeSuccess = () => {
    const previousValueS = currentValueS;
    currentValueS = store.getState().homeReducer.messageNotificationSuccess;

    if (previousValueS !== currentValueS) {
      toast(currentValueS);
      store.dispatch(messageDispatch('SUCCESS', currentValueS));
    }
  };

  const handleChangeError = () => {
    const previousValueE = currentValueE;
    currentValueE = store.getState().homeReducer.messageNotificationError;

    if (previousValueE !== currentValueE) {
      store.dispatch(messageDispatch('ERROR', currentValueE));
      toast.error(currentValueE);
    }
  };

  if (clinicId === 'health') {
    return (
      <div className="App">
        <script>Response.ok</script>
      </div>
    );
  }

  store.dispatch(fetchClinic(clinicId));
  store.dispatch(getExternalToken());

  return (
    <BrowserRouter basename={clinicId}>
      <Provider store={store}>
        <Layout />
        <ToastContainer />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
