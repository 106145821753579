import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { FormControl } from '../styles';

const DatetimeS = styled.div`
  margin-bottom: 22px;

  input {
    ${FormControl} width: 100%;
    box-shadow: none;

    &:focus {
      box-shadow: none;
      margin: 0;
    }

    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }
  .rdtOpen {
    .rdtPicker {
      width: 100%;
      color: ${(props) => props.fontColor || 'rgba(0, 0, 0, .8)'};

      th {
        font-family: Roboto;
      }
      td {
        font-family: Gotham-Book;
        border-radius: 50%;
        + .rdtActive,
        + .rdtActive:hover {
          background: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
        }
      }
      .rdtToday:before {
        border-bottom-color: ${(props) => props.colorsucses || 'rgb(31, 130, 224)'};
      }
      th,
      td {
        height: 40px;
      }
      @media (max-width: 1199px) {
        th,
        td {
          height: 28px;
        }
      }
    }
  }
`;

const ErrorText = styled.p`
  font-size: 14px;
  color: rgb(221, 0, 25);
  margin-bottom: 0;
  margin-top: 10px;
  font-family: Gotham-Medium;
`;

class InputDate extends Component {
  constructor() {
    super();
    this.state = {
      /*eslint-disable*/
      phone: '',
      birthday: '',
      firstName: '',
      lastName: '',
      email: '',
      /* eslint-enable */
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.datetimeRef.setState({ value: this.props.value || this.props.defaultValue });
    }
  }
  /*eslint-disable*/
  onChange(value, name) {
    this.setState({ [name]: value });
  }
  /* eslint-enable */

  render() {
    const {
      themeColor, fontColor, value, onDayChange, defaultValue, errors,
    } = this.props;
    let newValue = value;
    if (value === null) {
      newValue = defaultValue;
    }
    return (
      <DatetimeS colorsucses={themeColor} fontColor={fontColor}>
        <div>
          <Datetime
            onChange={onDayChange}
            dateFormat="MM-DD-YYYY"
            value={newValue}
            className="icon-calendar"
            timeFormat={false}
            inputProps={{
              placeholder: 'Date of Birth e.g. MM-DD-YYYY',
              name: 'birthday',
            }}
            ref={(ref) => { this.datetimeRef = ref; }}
          />
        </div>
        <ErrorText>{errors}</ErrorText>
      </DatetimeS>

    );
  }
}
InputDate.propTypes = {
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), //eslint-disable-line
  errors: PropTypes.string,
  defaultValue: PropTypes.string,
  onDayChange: PropTypes.func.isRequired,
};

InputDate.defaultProps = {
  defaultValue: moment(new Date()).locale('en').format('MM-DD-YYYY'),
  errors: '',
};

export default InputDate;
