import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';

const Buttons = styled.button`
  background-color: ${(props) => props.color || $primary};
  border: 0;
  border-radius: 44px;
  color: rgb(255, 255, 255);
  outline: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  min-width: 82px;
  padding: 10px 16px;
  margin: ${(props) => props.margin || 'auto'};
  ${(props) => props.styles} &:hover {
    // opacity: 0.6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 767px) {
    font-size: 1em;
  }
`;

function Button({
  type, onClick, disabled, children, themeColor, styles,
}) {
  return (
    <Buttons
      color={themeColor}
      disabled={disabled}
      onClick={onClick}
      type={type}
      styles={styles}
    >
      {children}
    </Buttons>
  );
}

Button.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func, //eslint-disable-line
  type: PropTypes.string,
  themeColor: PropTypes.string.isRequired,
  styles: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  type: 'button',
  styles: '',
};

const mapStateToProps = (state) => ({
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
});

export default connect(mapStateToProps)(Button);
