import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row } from 'react-bootstrap';
import Button from '../../../components/forms/Button';

const Message = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.fontColor || '#424242'};
`;

const FormModal = styled.form`
  margin-top: 5px;
`;

const ButtonModalDiv = styled.div`
  padding: 20px 0;
  text-align: center;
`;

const List = styled.li`
  width: 100%;
  list-style: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #424242;
  padding-top: 15px;
  label {
    margin-left: 15px;
  }
`;

function ValidateModal({
  /*eslint-disable*/
  title,
  /* eslint-enable */
  message,
  onChange,
  onSubmit,
  show,
  onHide,
  email,
  phone,
}) {
  const onChoose = (event) => {
    onChange(event);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return show ? (
    <div className={show && !onHide ? 'd-block' : ''}>
      <Row className="text-center">
        <Message>{message}</Message>
      </Row>
      <FormModal onSubmit={handleSubmit}>
        <div className="container">
          <ul>
            <List>
              <input
                type="radio"
                id="f-option"
                name="selector"
                value="email"
                onClick={onChoose}
              />
              <label htmlFor="f-option">
                Get the confirmation code via email:
                {' '}
                {email}
              </label>
            </List>
            <List>
              <input
                type="radio"
                id="s-option"
                name="selector"
                value="phone"
                onClick={onChoose}
              />
              <label htmlFor="s-option">
                Get the confirmation code via text:
                {' '}
                {phone}
              </label>
            </List>
          </ul>
        </div>
        <ButtonModalDiv className="text-center">
          <Button type="submit" onSubmit={handleSubmit}>
            Submit
          </Button>
        </ButtonModalDiv>
      </FormModal>
    </div>
  ) : null;
}

ValidateModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};

export default ValidateModal;
