import moment from 'moment';

export const getParams = (endpoint, data) => {
  let response;
  switch (endpoint) {
    case 'family-members':
      response = '';
      break;
    case 'profile-option':
      response = getFamilyMembersQueryParams(data);
      break;
    case 'services':
      response = getServicesQueryParams(data);
      break;
    case 'providers':
      response = getProvidersQueryParams(data);
      break;
    case 'approval':
      response = getApprovalQueryParams(data);
      break;
    default:
      break;
  }
  return response;
};

export const transformDate = (data, type) => {
  const format = getFormatType(type);
  return moment.utc(new Date(data), 'YYYY-MM-DD hh:mm:ss').format(format);
};

const getFormatType = (type) => {
  let response;
  switch (type) {
    case 'start':
      response = 'YYYY-MM-DDT00:00:00';
      break;
    case 'end':
      response = 'YYYY-MM-DDT23:59:59';
      break;
    default:
      response = 'YYYY-MM-DD hh:mm:ss';
      break;
  }
  return response;
};

const getFamilyMembersQueryParams = (data) => `/item/${encodeURIComponent(data)}`;

const getServicesQueryParams = (data) => `?patient_id=${encodeURIComponent(
  data.patientId,
)}
&hbid=${encodeURIComponent(data.hashBid)}`;

const getProvidersQueryParams = (data) => `?service_id=${encodeURIComponent(
  data.serviceId,
)}
&patient_id=${encodeURIComponent(data.patientId)}
&hbid=${encodeURIComponent(data.hashBid)}`;

const getApprovalQueryParams = (data) => `?token=${encodeURIComponent(data.token)}`;
