import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { loginUid } from '../../actions/authActions';

class LeadGenLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { dispatch, history } = this.props;

    const searchParams = new URLSearchParams(window.location.search);
    const sessionId = searchParams.get('session_id');
    dispatch(loginUid(sessionId, history));
  }

  render() {
    return (
      <div />
    );
  }
}

LeadGenLogin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthorized: state.userReducer.isAuthorized,
});

export default withRouter(connect(mapStateToProps)(LeadGenLogin));
