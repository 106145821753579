import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import StarRatingComponent from 'react-star-rating-component';
import {
  selectProvider,
  setDaysView,
  skipProvider,
} from '../../../actions/appointmentActions';
import StepHeader from '../AppointmentStepsHeader';
import AppointmentStepsHeaderNav from '../AppointmentStepsHeaderNav';
import Loading from '../../Loading';
import getProviders from '../../../actions/providersActions';
import {
  RadioPad,
  RadioLabel,
  RadioInput,
  Title,
  Name,
  ReviewsLink,
  Profession,
  AvatarProvider,
  ReviewsCount,
  Avatars,
  StarRating,
} from '../../../elements/appointmentSteps/Providers';
import BioModal from '../../../elements/modals/Providers/BioModal';
import StepsButton from '../StepsButton';

class Providers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProviderId: '',
      showBio: false,
      providerInfo: {},
    };
  }

  componentDidMount() {
    const data = {
      serviceId: parseInt(this.props.selectedServiceId, 0),
      patientId: this.props.patientId,
      hashBid: this.props.hashBid,
    };
    const { token } = this.props;
    this.props.dispatch(getProviders(token, data));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.providers !== nextProps.providers
      && nextProps.providers.length > 0
    ) {
      this.props.dispatch(skipProvider(false));
    }
  }

  handleRadio(e) {
    this.providerData = {
      targetId: parseInt(e.target.id, 10),
      targetName: e.target.name,
      targetValue: e.target.value,
    };

    // Convert the object to a JSON string
    const providerDataJSON = JSON.stringify(this.providerData);

    // Store the JSON string in sessionStorage
    sessionStorage.setItem('providerData', providerDataJSON);

    const targetId = parseInt(e.target.id, 0);
    const targetName = e.target.name;
    const targetValue = e.target.value;
    const data = {
      id: this.state.selectedProviderId !== targetId ? targetId : null,
      pmsId: targetName,
      name: targetValue,
    };
    this.props.dispatch(setDaysView(3));
    this.props.dispatch(selectProvider(data, this.props.history));
    this.props.dispatch(skipProvider(false));
  }

  handleBio(providerInfo) {
    this.setState({ showBio: true, providerInfo });
  }

  bioClose = () => {
    this.setState({ showBio: false });
  };

  render() {
    const {
      providers, themeColor, fontColor, fetchingProviders, phoneOffice,
    } = this.props;

    let renderProvidersRadio;

    const { providerInfo, showBio } = this.state;

    if (fetchingProviders) {
      renderProvidersRadio = (
        <Loading innerText="Loading..." themeColor={themeColor} />
      );
    } else if (providers.length > 0) {
      renderProvidersRadio = providers.map((provider) => {
        const data = JSON.parse(sessionStorage.getItem('providerData'));
        const isCurrent = provider.provider.id === data?.targetId;
        return (
          <Col key={provider.provider.id} xs={12} sm={12} md={12} lg={6}>
            <RadioPad
              colorsucses={themeColor}
              className={isCurrent ? 'select' : null}
            >
              <div>
                <Avatars>
                  <AvatarProvider
                    src={
                      provider.provider.photo !== ''
                        ? provider.provider.photo
                        : `${process.env.PUBLIC_URL}/img/photo-upload.png`
                    }
                    alt={provider.provider.name}
                  />
                  {(provider.provider.rating && provider.provider) > 0 ? (
                    <div style={{ textAlign: 'center' }}>
                      <StarRating>
                        <StarRatingComponent
                          name="rate"
                          starCount={5}
                          value={provider.provider.rating}
                          starColor={themeColor}
                          emptyStarColor="rgb(231, 231, 231)"
                          editing={false}
                        />
                      </StarRating>
                      <ReviewsCount color={fontColor}>
                        {provider.provider.reviewsCount > 0
                          ? `${provider.provider.reviewsCount} Reviews`
                          : null}
                      </ReviewsCount>
                    </div>
                  ) : null}
                </Avatars>
              </div>
              <div>
                <Title>
                  <Name fontColor={fontColor}>
                    {provider.provider.firstName}
                    {' '}
                    {provider.provider.lastName}
                  </Name>
                  {provider.provider.speciality
                  && (
                  <Profession fontColor={fontColor}>
                    {provider.provider.speciality}
                  </Profession>
                  )}
                  <ReviewsLink
                    color={themeColor}
                    onClick={() => this.handleBio(provider.provider)}
                  >
                    See More Info
                  </ReviewsLink>
                </Title>
                <RadioLabel
                  colorsucses={themeColor}
                  className={isCurrent ? 'select' : null}
                >
                  <RadioInput
                    type="radio"
                    name={provider.provider.pmsId}
                    id={provider.provider.id}
                    value={`${provider.provider.firstName} ${provider.provider.lastName}`}
                    onChange={(e) => this.handleRadio(e)}
                  />
                  Select Provider
                </RadioLabel>
              </div>
            </RadioPad>
          </Col>
        );
      });
    } else {
      renderProvidersRadio = (
        <Loading
          innerText={`We are unable to complete your request online.
          Please call the office at ${phoneOffice} to schedule your appointment.`}
          themeColor={themeColor}
        />
      );
    }

    return (

      <div className="steps-container">
        <BioModal
          title="Biography"
          show={showBio}
          onHide={this.bioClose}
          providerInfo={providerInfo}
          fontColor={fontColor}
          themeColor={themeColor}
        />
        <Row className="g-0 flex-shrink-0">
          <Col md={3} lg={3} sm={12}>
            <AppointmentStepsHeaderNav />
          </Col>
          <Col md={9} lg={9} sm={12}>
            <div className="main-content">
              <StepHeader
                title="Available Medical Providers"
                description="Select your provider for consultation."
              />
              <Row className="mt-5">
                {renderProvidersRadio}
              </Row>
              <StepsButton />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
Providers.propTypes = {
  dispatch: PropTypes.func.isRequired,
  providers: PropTypes.array.isRequired,
  themeColor: PropTypes.string.isRequired,
  fontColor: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  selectedServiceId: PropTypes.number.isRequired,
  fetchingProviders: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  patientId: PropTypes.number.isRequired,
  phoneOffice: PropTypes.string.isRequired,
  hashBid: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  token: state.userReducer.token,
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontColor: state.homeReducer.clinic.styleOptions.fontColor,
  providers: state.providerReducer.providers,
  selectedServiceId: state.appointmentReducer.request.serviceId,
  fetchingProviders: state.providerReducer.fetchingProviders,
  patientId: state.appointmentReducer.request.patientId,
  phoneOffice: state.homeReducer.clinic.clinicInfo.phone,
  hashBid: state.appointmentReducer.request.hashBid,
});

export default withRouter(connect(mapStateToProps)(Providers));
