import React from 'react';
import PropTypes from 'prop-types';
import {
  Container, Navbar, Nav,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../actions/authActions';
import { exitIcon } from '../icons/userIcons';
import {
  Image,
  NotImage,
  Avatar,
  AvatarHeader,
  AvatarTitle,
  LogOut,
  LoginedUser,
  FirstLastName,
  HeaderTitle,
  Root,
} from '../../elements/pageHeader';

function PageHeader({
  clinicTitle,
  clinicPhone,
  clinicLogoSrc,
  html,
  isAuthorized,
  dispatch,
  themeColor,
  user,
  history,
}) {
  const renderBrandBlock = () => {
    if (!isAuthorized) {
      return (
        <>
          <Navbar
            bg="white"
            variant="light"
            className="justify-content-center pb-4"
          >
            <Navbar.Brand>
              {clinicLogoSrc ? (
                <Image src={clinicLogoSrc} alt="Logo" />
              ) : (
                <NotImage>Logo</NotImage>
              )}
              <div>
                <small className="d-block ">{clinicTitle}</small>
                <small className="d-block">{clinicPhone}</small>
              </div>
            </Navbar.Brand>
          </Navbar>
          <div className="py-4">
            <HeaderTitle color={themeColor}>{html}</HeaderTitle>
          </div>
        </>
      );
    }
    return null;
  };

  const renderLoginUserBlock = () => {
    const { firstName, photo } = user.accountInfo;
    if (isAuthorized) {
      return (
        <Navbar expand="lg" className="bg-body-tertiary mmg-navbar">
          <Navbar.Brand className="d-flex justify-content-between align-items-center">
            <div>
              {clinicLogoSrc ? (
                <Image src={clinicLogoSrc} alt="Logo" className="client-logo" />
              ) : (
                <NotImage>Logo</NotImage>
              )}
              <small className="d-block ">{clinicTitle}</small>
              <small className="d-block">{clinicPhone}</small>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <div className="d-flex user-info align-items-center">
                {photo ? (
                  <AvatarHeader src={photo} />
                ) : (
                  <Avatar color={themeColor}>
                    <AvatarTitle>
                      {`${firstName.charAt(0)}`}
                    </AvatarTitle>
                  </Avatar>
                )}
                <LoginedUser
                  color={themeColor}
                  to={`/profile${sessionStorage.getItem('_ga') ? sessionStorage.getItem('_ga') : ''}`}
                >
                  <FirstLastName color={themeColor}>
                    {firstName}
                  </FirstLastName>
                </LoginedUser>
                <div>
                  <LogOut
                    color={themeColor}
                    to="/login"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(logout(history, dispatch));
                    }}
                  >
                    {exitIcon()}
                    <span>Logout</span>
                  </LogOut>
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      );
    }
    return null;
  };

  return (
    <Root>
      <Container fluid>
        {renderBrandBlock()}
        {renderLoginUserBlock()}
      </Container>
    </Root>
  );
}

PageHeader.propTypes = {
  clinicTitle: PropTypes.string.isRequired,
  clinicPhone: PropTypes.string.isRequired,
  clinicLogoSrc: PropTypes.string.isRequired,
  html: PropTypes.string.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  themeColor: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

PageHeader.defaultProps = {
  photo: null,
};

const mapStateToProps = (state) => ({
  user: state.userReducer,
  themeColor: state.homeReducer.clinic.styleOptions.themeColor,
  fontColor: state.homeReducer.clinic.styleOptions.fontColor,
  token: state.userReducer.token,
});

export default withRouter(connect(mapStateToProps)(PageHeader));
