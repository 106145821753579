import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = styled.img`
  padding: 40px;
`;

function SideImage(props) {
  return <Image src={props.src} width={props.width} />;
}

SideImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.string,
};
SideImage.defaultProps = {
  width: '100%',
};

export default SideImage;
