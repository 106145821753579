import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Root = styled.div`
  background-color: rgb(255, 255, 255);
  display: flex;

  @media (max-width: 1199px) {
    justify-content: space-around;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

Root.displayName = 'Root';

export const AvatarHeader = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  @media (max-width: 767px) {
    margin-left: 15px;
  }
`;

AvatarHeader.displayName = 'AvatarHeader';

export const Brand = styled.div`
  display: flex;
  min-height: 130px;
  align-items: center;
  flex: 1;
  padding-left: 40px;

  @media (max-width: 991px) {
    padding-left: 15px;
  }

  @media screen\0 {
    padding-top: 40px;
  }

  @media (max-width: 767px) {
    min-height: 80px;
    justify-content: space-around;
    display: block;
    text-align: center;
    margin-top: 10px;
  }
`;

Brand.displayName = 'Brand';

export const BrandTxt = styled.p`
  margin-left: 30px;
  margin-bottom: 0;
  font-size: 1.3em;
  font-family: Gotham-Book;
  color: ${(props) => props.fontColor || 'rgb(59, 62, 74)'};
  // opacity: .6;
  font-weight: bold;

  @media (max-width: 767px) {
    margin-left: 5px;
    font-size: 0.8em;
    text-align: center;
    margin-top: 15px;
    border-bottom: 5px;
  }
`;

BrandTxt.displayName = 'BrandTxt';

export const HeaderContent = styled.div`
  min-height: 130px;
  display: flex;
  align-items: center;

  @media screen\0 {
    padding-top: 40px;
  }

  @media (max-width: 767px) {
    min-height: 80px;
  }
`;

HeaderContent.displayName = 'HeaderContent';

export const HeaderTitle = styled.h4`
  color: ${(props) => props.color || '#424242'};
  text-align: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  text-transform: capitalize;
  @media (max-width: 767px) {
    text-align: center;
    font-size: 1.4em;
  }
`;

HeaderTitle.displayName = 'HeaderTitle';

export const LogOut = styled(Link)`
  padding: 8px 16px;
  color: #fff;
  text-decoration: none;
  border-radius: 25px;
  background-color: ${(props) => props.color || '#258ADA'};
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  &&:hover{
    color: #fff;
  }
  @media (max-width: 767px) {
    margin-left: 0;
    width: auto;

    > svg {
      width: 20px;
      height: 20px;
    }
  }

  > svg {
    opacity: 1;
    fill-rule: evenodd;
  }

  &:hover {
    > svg {
      opacity: 1;
    }
  }
`;

LogOut.displayName = 'LogOut';

export const Logined = styled.div`
  display: flex;
  min-height: 130px;
  align-items: center;
  justify-content: space-around;
  flex: 0.5;

  @media screen\0 {
    padding-top: 40px;
  }

  @media (max-width: 1199px) {
    padding-right: 40px;
  }

  @media (max-width: 991px) {
    padding-right: 15px;
  }

  @media (max-width: 767px) {
    min-height: 80px;
    justify-content: space-around;
  }

  hr {
    width: 1px;
    height: 60px;
    border-left: 1px solid rgba(0, 0, 0, 0.06);
    margin: 0;

    @media (max-width: 1199px) {
      margin-right: 15px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  a {
    color: ${(props) => props.fontColor || 'rgba(0, 0, 0, 1)'};

    @media (max-width: 1199px) {
      margin-left: 15px;
    }
  }

  svg {
    fill: ${(props) => props.fontColor || 'rgba(59, 62, 74)'};
  }

  a:hover,
  a:focus {
    color: ${(props) => props.color || 'rgb(31, 130, 224)'};
    svg {
      fill: ${(props) => props.color || 'rgb(31, 130, 224)'};
    }
  }
`;

Logined.displayName = 'Logined';

export const FirstLastName = styled.h4`
  font-size: 1rem;
  color: ${(props) => props.color || '#258ADA'};
  font-weight: 700;
  margin: 0 1.2rem 0 0;
  text-transform: capitalize;
  @media (max-width: 767px) {
    // font-size: 0.8em;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

FirstLastName.displayName = 'FirstLastName';

export const LoginedUser = styled(Link)`
  opacity: 0.8;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  &:hover,
  &:focus {
    opacity: 1;
   
  }

  @media (max-width: 767px) {
    width: 50%;
  }
`;

LoginedUser.displayName = 'LoginedUser';

export const Image = styled.img`
  max-height: 115px;

  @media (max-width: 767px) {
    max-height: 80px;
    margin-top: 20px;
  }
`;

Image.displayName = 'Image';

export const NotImage = styled.p`
  background: rgb(246, 246, 246);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
`;

NotImage.displayName = 'NotImage';

export const Avatar = styled.div`
  height: 40px;
  width: 40px;
  background-color: ${(props) => props.color || '#258ADA'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

Avatar.displayName = 'Avatar';

export const AvatarTitle = styled.p`
  font-size: 1rem;
  color: #FFF;
  font-weight: 700;
  margin: 0;
  text-transform: capitalize;
`;

AvatarTitle.displayName = 'AvatarTitle';
