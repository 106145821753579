import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import FormElem from '../../../components/forms/FormElem';
import Button from '../../../components/forms/Button';

const Message = styled.p`
  margin: 16px 0 0;
  line-height: 1.71;
  color: ${(props) => props.fontColor || 'rgb(0, 0, 0)'};
  text-align: center;
  opacity: 0.8;
`;

const ButtonModalDiv = styled.div`
  margin-top: 20px;
`;

const FormModal = styled.form`
  @media (max-width: 767px) {
    margin: 0 -15px;
    display: block;
    text-align: left;
    margin-top: 10px;
  }
`;

function SignInFormModal({
  message,
  errors,
  label,
  pinInput,
  type,
  successButton,
  succesSection,
  show,
  onHide,
  onChange,
  onConfirmCode,
  onRedirectLogin,
  onSubmit,
}) {
  return show ? (
    <Row>
      <>
        <Col>
          <Message dangerouslySetInnerHTML={{ __html: message }} />
        </Col>
        {succesSection ? (
          <div className="text-center mt-4">
            <ButtonModalDiv>
              <Button onClick={onRedirectLogin}>{successButton}</Button>
            </ButtonModalDiv>
          </div>
        ) : (
          <div>
            <FormModal onSubmit={onSubmit} className="p-4">
              <FormElem
                type={type}
                valid={[
                  {
                    name: 'noEmpty',
                    message: errors && errors[type],
                  },
                ]}
                onChange={onChange}
                placeholder={label}
                labelContent={label}
              />
              {pinInput ? (
                <FormElem
                  type={type}
                  valid={[
                    {
                      name: 'noEmpty',
                      message: errors && errors.pin,
                    },
                  ]}
                  name="text"
                  onChange={onConfirmCode}
                  placeholder="Pin Code"
                  labelContent="Pin Code"
                />
              ) : null}
              <ButtonModalDiv>
                <div>
                  <Button type="submit">Submit</Button>
                </div>
                {!pinInput ? (
                  <div className="d-flex align-items-center justify-content-center gap-2 row-colunm mt-5">
                    <span>Go back to Sign In?</span>
                    <span>
                      <Button type="button" onClick={onHide}>
                        Sign In
                      </Button>
                    </span>
                  </div>
                ) : null}
              </ButtonModalDiv>
            </FormModal>
          </div>
        )}
      </>
    </Row>
  ) : null;
}

SignInFormModal.propTypes = {
  message: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  pinInput: PropTypes.number,
  onConfirmCode: PropTypes.func,
  succesSection: PropTypes.number,
  onRedirectLogin: PropTypes.func,
  successButton: PropTypes.string,
};

SignInFormModal.defaultProps = {

};

export default SignInFormModal;
