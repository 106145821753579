export default {
  fetching: false,
  fetched: false,
  errors: {},
  messageNotificationSuccess: '',
  messageNotificationError: '',
  clinic: {},
  services: [],
  providers: [],
  practicesList: [],
  practicesOptions: [],
};
